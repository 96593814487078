/* eslint-disable no-unused-vars */
import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { useEffect, useRef, useState } from 'react'
import './businessform.scss'
import FormWrapper from '../_components/FormWrapper'
import BusinessInfo from './components/BusinessInfo'
import ContactInfo from './components/ContactInfo'
import PropTypes from 'prop-types'
import { useAppContext } from 'src/appContext/AppContext'
import Toast from 'src/components/Toast'
import { isMobile } from 'src/utils/mediaQueries'
import { validateBusinessInfoKyc } from 'src/utils/validationSchema'
import { formatYupError } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import { BUSINESS_INFO_UPDATE_REQUESTS_MAP } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'
import { decodeUserInfo } from 'src/utils/auth'

const BusinessFormTabs = ({
    activeTab,
    setActiveTab,
    handleTabChange,
    updateErrors,
    removeFromUpdateErrs,
    setSubmitHandler,
    setSaveHandler,
    setShowVideoModal,
}) => (
    <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Business Info" key="1">
            <BusinessInfo
                activeTab={activeTab}
                setCurrentTab={setActiveTab}
                setSubmitHandler={setSubmitHandler}
                setSaveHandler={setSaveHandler}
                updateErrors={updateErrors}
                removeFromUpdateErrs={removeFromUpdateErrs}
                setShowVideoModal={setShowVideoModal}
            />
        </TabPane>
        <TabPane tab="Contact info" key="2">
            <ContactInfo
                activeTab={activeTab}
                setCurrentTab={setActiveTab}
                setSubmitHandler={setSubmitHandler}
                setSaveHandler={setSaveHandler}
                updateErrors={updateErrors}
                removeFromUpdateErrs={removeFromUpdateErrs}
                setShowVideoModal={setShowVideoModal}
            />
        </TabPane>
    </Tabs>
)

const BusinessFormWrapper = ({
    scrollRef,
    submitToastMessage,
    submitToastType,
    setSubmitToastMessage,
    children,
}) => (
    <FormWrapper>
        <div className="BusinessFormWrapper" ref={scrollRef}>
            <Toast
                message={submitToastMessage}
                messageType={submitToastType}
                setToast={setSubmitToastMessage}
                closable={true}
                position="fixed"
                left={isMobile ? '0%' : '20%'}
                top={isMobile ? '24%' : '10.5%'}
                zIndex="5"
                width={isMobile ? '100%' : '60%'}
            />
            {children}
        </div>
    </FormWrapper>
)

const BusinessForm = ({ setSubmitHandler, setSaveHandler }) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const shouldDisplayVideo =
        !isAdminUser && process.env.REACT_APP_FLAG_SHOW_HOW_TO_VIDEO === 'true'

    const [updateErrors, setUpdateErrors] = useState({})
    const [showVideoModal, setShowVideoModal] = useState(shouldDisplayVideo)
    const [showVideo, setShowVideo] = useState(false)
    const {
        submitToastMessage,
        submitToastType,
        setSubmitToastMessage,
        setCurrentForm,
        activeTab,
        setActiveTab,
        businessInfoFormik,
    } = useAppContext()
    const { errorAlert } = useCustomToast()
    const {
        values: businessInfoValues,
        setErrors,
        setTouched,
    } = businessInfoFormik
    const mappedErrors = useMappedErrors(BUSINESS_INFO_UPDATE_REQUESTS_MAP)
    const scrollRef = useRef(null)

    useEffect(() => {
        function scrolldiv() {
            var element = document.getElementById('scrolltoview_residence')
            element.scrollIntoView()
        }
        scrolldiv()
    }, [activeTab])

    const handleTabChange = activeKey => {
        if (activeKey === '2' && !isAdminUser) {
            validateBusinessInfoKyc
                .validate(businessInfoValues, {
                    abortEarly: false,
                })
                .then(() => {
                    setCurrentForm('contactInfo')
                    setActiveTab(activeKey)
                })
                .catch(err => {
                    const errList = formatYupError(err)
                    setTouched(errList)
                    setErrors(errList)
                    errorAlert(Object.values(errList)[0])
                })
        } else {
            setActiveTab(activeKey)
            setCurrentForm('businessInfo')
        }
    }

    const removeFromUpdateErrs = field => {
        const newErrs = { ...updateErrors }
        delete newErrs[field]
        setUpdateErrors(newErrs)
    }

    useEffect(() => {
        setUpdateErrors(mappedErrors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappedErrors])

    return (
        <>
            <BusinessFormWrapper
                scrollRef={scrollRef}
                submitToastMessage={submitToastMessage}
                submitToastType={submitToastType}
                setSubmitToastMessage={setSubmitToastMessage}
            >
                {shouldDisplayVideo && (
                    <div
                        className="BusinessFormPageVideo"
                        onClick={() => setShowVideoModal(true)}
                    >
                        <PlayIcon />
                        <p>Watch how to submit your info</p>
                    </div>
                )}
                <BusinessFormTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                    updateErrors={updateErrors}
                    removeFromUpdateErrs={removeFromUpdateErrs}
                    setSubmitHandler={setSubmitHandler}
                    setSaveHandler={setSaveHandler}
                    setShowVideoModal={setShowVideoModal}
                />
            </BusinessFormWrapper>
            {(showVideoModal || showVideo) && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle="Get started with your business KYC"
                    header="Submit your business information"
                    videoInfo="Here’s a video to guide you on how you submit your business information"
                    videoUrl="https://www.youtube.com/watch?v=VuCoYqTXa58"
                    videoGIF="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Fill_Your_Registered_Business_Information.gif"
                    description="We need you to submit your business information such as Registered Business Name, CAC number,  Number of years in operation, and a contact person details (Full name, Phone number and email address)"
                />
            )}
        </>
    )
}

BusinessFormTabs.propTypes = {
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    handleTabChange: PropTypes.func,
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    setSubmitHandler: PropTypes.func,
    setSaveHandler: PropTypes.func,
    setShowVideoModal: PropTypes.func,
}

BusinessFormWrapper.propTypes = {
    scrollRef: PropTypes.string,
    submitToastMessage: PropTypes.func,
    submitToastType: PropTypes.func,
    setSubmitToastMessage: PropTypes.func,
    children: PropTypes.node,
}

BusinessForm.propTypes = {
    setSubmitHandler: PropTypes.func,
    setSaveHandler: PropTypes.func,
}

export default BusinessForm
