import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import { ReactComponent as CheckMark } from 'src/assets/images/round-check-mark.svg'
import { ReactComponent as CircleMinus } from 'src/assets/images/circle_minus.svg'
import { ReactComponent as Goback } from 'src/assets/images/blue-circle-left.svg'
import { ReactComponent as WarningInfo } from 'src/assets/images/warning-info.svg'
import { ReactComponent as WarningInfoLarge } from 'src/assets/images/warning-info-large.svg'
import { ReactComponent as CalendarIcon } from 'src/assets/images/calendar-icon.svg'
import { ReactComponent as CancelIcon } from 'src/assets/images/cancel-icon.svg'
import { ReactComponent as OneIcon } from 'src/assets/images/01Circle.svg'
import { ReactComponent as TwoIcon } from 'src/assets/images/02Circle.svg'
import { ReactComponent as ThreeIcon } from 'src/assets/images/03Circle.svg'
import { ReactComponent as MandateIcon } from 'src/assets/images/mandate-sample.svg'
import Lottie from 'react-lottie'
import styles from './decisionisyes.module.scss'
import { useReducer } from 'react'
import {
    initialValues,
    PAYMENT_PLANS,
    reducer,
    SELECT_PAYMENT_PLAN,
    DEFAULT_SELECT_PAYMENT_PLAN,
    SET_CARD_DETAILS,
    SET_DEAL_APPROVAL_MODAL,
    SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION,
    SET_DIRECT_MANDATE,
    SET_PAYMENT_DETAILS_MODAL,
    SET_PAYMENT_PLAN_MODAL,
    SET_PAYMENT_SETUP,
    SET_PAYMENT_TYPE,
    SET_PAYSTACK_LOADING,
    SET_PAYSTACK_MODAL,
    SET_PAYSTACK_VERIFYING,
    SET_SUB_CARD_DETAILS,
    SET_SUB_DIRECT_DETAILS,
    SET_SUB_PAYMENT_SUCCESS,
    SET_SUBSCRIPTION_DETAILS_MODAL,
    SET_SUBSCRIPTION_TENURE,
    SET_SUBSCRIPTION_TENURE_MODAL,
    SET_UPFRONT_DEPOSIT_MODAL,
    SET_DIRECT_DEBIT_TYPES,
    SET_SELECTED_DIRECT_DEBIT_TYPE,
    SET_AGREEMENT_SIGNING_MODAL,
    SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL,
} from './reducer'
import ActionModal from 'src/components/ActionModalV2'
import PayStack from '../PayStack'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import CardDetails from '../CardDetails'
import { isMobile } from 'react-device-detect'
import animationData from 'src/assets/lottie/celebration'
import PropTypes from 'prop-types'
import { SelectField } from 'src/components/InputFields'
import { paymentPlanTenure } from 'src/utils/paymentPlanTenure'
import BackNav from 'src/components/BackNav'
import SetupDirectDebitKyc from '../SetupDirectDebit'
import CardDetailsKyc from '../CardDetailsKyc'
import RemitaLogo from 'src/assets/images/remita-logo.svg'
import { useSSEPersistRepaymentTenure } from 'src/api/shoppingExperience/consumer-estimation/queries'
import { format } from 'date-fns'
import {
    useExistingCard,
    useSelectSSEPaymentPlanWithUpfrontDeposit,
    useSimulateSSEPaymentPlans,
} from '../../utils/queries'
import { useDirectDebitRepaymentSetup } from '../../utils/hook'
import {
    useRetrieveSSEPaymentPlansData,
    useSelectSSEPaymentPlan,
    useSendAgreementReminderEmail,
} from '../../utils/queries'
import DecisionIsMaybe from '../DecisionIsMaybe'
import { ConsumerButton } from 'src/components/Button'
import { numberWithCommas } from 'src/utils/formatting'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'
import { IconInputField } from 'src/components/InputFields/CostField'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import Verify from 'src/components/GettingStarted/components/Verify'
import Toast from 'src/components/Toast'
import NairaSymbol from 'src/components/NairaSymbol'
import CustomToolTip from 'src/components/CustomToolTip'
import infoIcon from 'src/assets/images/warning-info-thick.svg'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { paymentTypeList, directDebitTypeList } from './data'
import { ReactComponent as CheckIcon } from 'src/assets/images/light-blue-circle-down-arrow.svg'
import { useSubmitPagaAuth } from 'src/api/paga/auth/queries'
import CardInstructions from '../CardInstructions'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'

const DecisionIsYes = ({
    showPaymentPlan,
    setShowPaymentPlan,
    showSubscriptionPlan,
    setShowSubscriptionPlan,
    updates,
    showPaystackModal,
    setShowPaystackModal,
    consumerFirstName,
    isWithSuggestion,
    estimationId,
    refetchUpdates,
    consumerEmail,
    consumerEstimationData,
    accountDetails,
    repaymentMethodType,
    showSubTenure,
    setShowSubTenure,
    isPendingAgreementSigning,
    showAgreementSigningConfirmationModal,
    setShowAgreementSigningConfirmationModal,
}) => {
    const [state, dispatch] = useReducer(reducer, initialValues)
    const { errorAlert } = useCustomToast()

    const showAgreementModal =
        process.env
            .REACT_APP_FLAG_SHOW_CONSUMER_WORKSPACE_AGREEMENT_SIGNING_MODAL ===
        'true'

    const [callInitializePayment, setCallInitializePayment] = useState(true)
    const history = useHistory()
    const pageTitle =
        'CREDIT DECISION - MAYBE | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREDIT_DECISION'
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    const [otherOffers, setOtherOffers] = useState(false)
    const [showNewDeposit, setShowNewDeposit] = useState(false)
    const [newDeposit, setNewDeposit] = useState('')
    const [newDepositError, setNewDepositError] = useState('')
    const [selectedCard, setSelectedCard] = useState(false)
    const [showInstruction, setShowInstruction] = useState(false)
    const [showCardInstructionError, setShowCardInstructionError] =
        useState(false)
    const [showRepaymentScheduleWarning, setShowRepaymentScheduleWarning] =
        useState(false)
    const [showRecovaSuccessModal, setShowRecovaSuccessModal] = useState(false)

    const recentUpdatesEstimationId = updates?.map(item => item?.estimation_id)
    const canSelectDirectDebit =
        consumerEstimationData?.filter(estimation =>
            recentUpdatesEstimationId?.includes(estimation.id),
        )?.[0]?.financing_start_date !== null

    const paystackDirectDebit =
        process.env.REACT_APP_SHOW_PAYSTACK_DIRECT_DEBIT === 'true' &&
        repaymentMethodType.includes('PAYSTACK:DIRECT_DEBIT')

    const remitaDirectDebit =
        process.env.REACT_APP_ENABLE_REMITTA_DIRECT_DEBIT === 'true' &&
        repaymentMethodType.includes('REMITA:DIRECT_DEBIT')

    const recovaDirectDebit =
        process.env.REACT_APP_ENABLE_RECOVA_DIRECT_DEBIT === 'true' &&
        repaymentMethodType.includes('RECOVA:DIRECT_DEBIT')

    const paystackCardDebit = repaymentMethodType.includes(
        'PAYSTACK:CARD_DEBIT',
    )

    const paymentTypes = () => {
        const result = [{ value: 'Card Debit', disabled: false }]
        const isDirectDebit = process.env.REACT_APP_ENABLE_REMITTA_DIRECT_DEBIT
        isDirectDebit === 'true' &&
            result.push({
                value: `Direct Debit${
                    canSelectDirectDebit
                        ? ''
                        : ' (Your repayment schedule is yet to be setup, please reach out to us)'
                }`,
                disabled: !canSelectDirectDebit,
            })
        return result
    }

    const handleSelectPlans = index => {
        dispatch({
            type: SELECT_PAYMENT_PLAN,
            payload: index,
        })
    }

    const handledefaulSelectedPlans = index => {
        dispatch({
            type: DEFAULT_SELECT_PAYMENT_PLAN,
            payload: index,
        })
    }

    const handleShowPaymentPlans = () => {
        dispatch({
            type: SET_DEAL_APPROVAL_MODAL,
            payload: false,
        })
        dispatch({
            type: SET_PAYMENT_PLAN_MODAL,
            payload: true,
        })
        setShowPaymentPlan({
            primary: false,
            secondary: true,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseShowPaymentPlans'].action,
            eventTrackers['sseShowPaymentPlans'].label,
            eventTrackers['sseShowPaymentPlans'].category,
        )
    }
    const handleSelectSubscriptionTenure = () => {
        if (updates[0]?.category === 'upfront_deposit') {
            dispatch({
                type: SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION,
                payload: false,
            })
            setShowSubscriptionPlan({
                primary: false,
                secondary: false,
            })
            setShowPaystackModal(true)
        } else {
            history.push({
                pathname: '/consumer/workspace',
                state: {
                    isSubscription: false,
                },
            })
            dispatch({
                type: SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION,
                payload: false,
            })
            setShowSubscriptionPlan({
                primary: false,
                secondary: true,
            })
            dispatch({
                type: SET_SUBSCRIPTION_TENURE_MODAL,
                payload: true,
            })
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseTenureSetup'].action,
                eventTrackers['sseTenureSetup'].label,
                eventTrackers['sseTenureSetup'].category,
            )
        }
    }
    const handleShowPaymentDetails = () => {
        dispatch({
            type: SET_PAYMENT_DETAILS_MODAL,
            payload: true,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseShowPaymentPlanDetails'].action,
            eventTrackers['sseShowPaymentPlanDetails'].label,
            eventTrackers['sseShowPaymentPlanDetails'].category,
        )
    }
    const handleShowSubscriptionDetails = () => {
        dispatch({
            type: SET_SUBSCRIPTION_DETAILS_MODAL,
            payload: true,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseShowSubscriptionDetails'].action,
            eventTrackers['sseShowSubscriptionDetails'].label,
            eventTrackers['sseShowSubscriptionDetails'].category,
        )
    }

    const handleSelectTenure = value => {
        dispatch({
            type: SET_SUBSCRIPTION_TENURE,
            payload: value,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseSelectedTenure'].action,
            eventTrackers['sseSelectedTenure'].label,
            eventTrackers['sseSelectedTenure'].category,
            ['MP', 'GA'],
            'event',
            { value },
        )
    }
    const handlePaymentType = value => {
        dispatch({
            type: SET_PAYMENT_TYPE,
            payload: value,
        })
    }

    const handleDirectDebitType = value => {
        dispatch({
            type: SET_SELECTED_DIRECT_DEBIT_TYPE,
            payload: value,
        })
    }

    const handleDisplayDirectDebitPage = () => {
        dispatch({
            type: SET_SUB_DIRECT_DETAILS,
            payload: true,
        })
        dispatch({
            type: SET_DIRECT_DEBIT_TYPES,
            payload: false,
        })
    }

    const handleFlexibleTenure = () => {
        dispatch({
            type: SET_SUBSCRIPTION_TENURE,
            payload: state.subscriptionTenure === 'flexible' ? '' : 'flexible',
        })
    }
    const handleShowUpfrontDeposit = () => {
        dispatch({
            type: SET_UPFRONT_DEPOSIT_MODAL,
            payload: true,
        })
        dispatch({
            type: SET_PAYMENT_PLAN_MODAL,
            payload: false,
        })
        setShowPaymentPlan({ primary: false, secondary: false })
    }

    const handleShowUpfrontDepositModal = () => {
        refetchUpdates()
        dispatch({
            type: SET_UPFRONT_DEPOSIT_MODAL,
            payload: false,
        })

        if (showAgreementModal && isPendingAgreementSigning) {
            setTimeout(() => {
                dispatch({
                    type: SET_AGREEMENT_SIGNING_MODAL,
                    payload: true,
                })
            }, 600)
        } else {
            setTimeout(() => {
                dispatch({
                    type: SET_PAYSTACK_MODAL,
                    payload: true,
                })
            }, 1200)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['ssePayUpfrontDeposit'].action,
                eventTrackers['ssePayUpfrontDeposit'].label,
                eventTrackers['ssePayUpfrontDeposit'].category,
            )
        }
    }

    const openCardDetailsModal = () => {
        dispatch({
            type: SET_CARD_DETAILS,
            payload: true,
        })
        setCallInitializePayment(!callInitializePayment)
    }

    const handleShowPaymentSetup = () => {
        setShowSubTenure(false)
        dispatch({
            type: SET_SUBSCRIPTION_DETAILS_MODAL,
            payload: false,
        })

        process.env.REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT !== 'true' &&
            dispatch({
                type: SET_PAYMENT_SETUP,
                payload: true,
            })

        if (process.env.REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT === 'true') {
            if (remitaDirectDebit || paystackDirectDebit || recovaDirectDebit) {
                dispatch({
                    type: SET_DIRECT_DEBIT_TYPES,
                    payload: true,
                })
                setAccountNumber(accountDetails?.account_number)
                setAccountName(accountDetails?.account_name)
                setBankDetails({
                    bankCode: accountDetails?.bank_code,
                    bankName: accountDetails?.bank_name,
                })
            }

            if (paystackCardDebit) {
                dispatch({
                    type: SET_SUB_CARD_DETAILS,
                    payload: true,
                })

                dispatch({
                    type: SET_PAYMENT_TYPE,
                    payload: 'Card Debit',
                })
            }
        }

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['showRepaymentType'].action,
            eventTrackers['showRepaymentType'].label,
            eventTrackers['showRepaymentType'].category,
        )
    }
    const handlePagaSetup = () => {
        resetSubModalState()
        window.open(
            `https://beta.mypaga.com/paga-webservices/oauth2/authorization?client_id=${process.env.REACT_APP_PAGA_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_PAGA_REDIRECT_URI}&state=state&scope=MERCHANT_PAYMENT+USER_DETAILS_REQUEST`,
            '_blank',
        )
    }

    const handleCardDebitSetup = () => {
        dispatch({
            type: SET_SUBSCRIPTION_DETAILS_MODAL,
            payload: false,
        })
        dispatch({
            type: SET_PAYMENT_SETUP,
            payload: false,
        })
        dispatch({
            type: SET_SUB_CARD_DETAILS,
            payload: true,
        })
        setShowSubscriptionPlan({
            primary: false,
            secondary: true,
            tertiary: false,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['showCardDebitSetup'].action,
            eventTrackers['showCardDebitSetup'].label,
            eventTrackers['showCardDebitSetup'].category,
        )
    }

    const handleDirectDebitSetup = () => {
        dispatch({
            type: SET_SUBSCRIPTION_DETAILS_MODAL,
            payload: false,
        })
        dispatch({
            type: SET_PAYMENT_SETUP,
            payload: false,
        })
        dispatch({
            type: SET_SUB_DIRECT_DETAILS,
            payload: true,
        })
        setShowSubscriptionPlan({
            primary: false,
            secondary: true,
            tertiary: false,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['showDirectDebitSetup'].action,
            eventTrackers['showDirectDebitSetup'].label,
            eventTrackers['showDirectDebitSetup'].category,
        )
    }

    const showMandate = () => {
        dispatch({
            type: SET_SUB_DIRECT_DETAILS,
            payload: false,
        })
        dispatch({
            type: SET_DIRECT_MANDATE,
            payload: true,
        })
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['showMandate'].action,
            eventTrackers['showMandate'].label,
            eventTrackers['showMandate'].category,
        )
    }

    const recovaModal = () => {
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: false,
        })
        setShowRecovaSuccessModal(true)
    }

    const closeMandate = () => {
        dispatch({
            type: SET_DIRECT_MANDATE,
            payload: false,
        })
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: false,
        })
        dispatch({
            type: SET_SUBSCRIPTION_TENURE_MODAL,
            payload: false,
        })
        setShowSubTenure(false)
        dispatch({
            type: SET_PAYMENT_TYPE,
            payload: '',
        })
    }

    const closeNonMandate = () => {
        dispatch({
            type: SET_SUBSCRIPTION_TENURE_MODAL,
            payload: false,
        })
        setShowSubTenure(false)
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: false,
        })
        dispatch({
            type: SET_SUB_PAYMENT_SUCCESS,
            payload: true,
        })

        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['showNonMandate'].action,
            eventTrackers['showNonMandate'].label,
            eventTrackers['showNonMandate'].category,
        )
    }

    const closeSubModal = () => {
        dispatch({
            type: SET_SUBSCRIPTION_TENURE_MODAL,
            payload: false,
        })
        setShowSubTenure(false)
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: false,
        })
    }

    const repaymentDetails = useCallback(() => {
        const details = updates?.find(
            update => update.category === 'repayment_setup',
        )

        return {
            monthly_payment: details?.context.monthly_payment,
            start_date: details?.context?.first_repayment_due_date,
            hasTenure: [undefined, null].includes(
                details?.context?.selected_tenure,
            )
                ? false
                : true,
        }
    }, [updates])

    const { submitSSERepaymentTenure, sseRepaymentTenureLoading } =
        useSSEPersistRepaymentTenure({
            onSuccess: () => {
                handleShowPaymentSetup()
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSubmitSelectedTenureSuccesful'].action,
                    eventTrackers['sseSubmitSelectedTenureSuccesful'].label,
                    eventTrackers['sseSubmitSelectedTenureSuccesful'].category,
                )
            },
        })

    const payloadTenure =
        state.subscriptionTenure === 'flexible'
            ? 0
            : state.subscriptionTenure.split(' ')[0]

    useEffect(() => {
        if (
            repaymentDetails()?.hasTenure === true ||
            showSubscriptionPlan?.tertiary
        ) {
            dispatch({
                type: SET_SUBSCRIPTION_DETAILS_MODAL,
                payload: false,
            })

            if (process.env.REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT !== 'true') {
                dispatch({
                    type: SET_PAYMENT_SETUP,
                    payload: true,
                })
            }

            if (process.env.REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT === 'true') {
                if (
                    remitaDirectDebit ||
                    paystackDirectDebit ||
                    recovaDirectDebit
                ) {
                    dispatch({
                        type: SET_DIRECT_DEBIT_TYPES,
                        payload: true,
                    })
                    setAccountNumber(accountDetails?.account_number)
                    setAccountName(accountDetails?.account_name)
                    setBankDetails({
                        bankCode: accountDetails?.bank_code,
                        bankName: accountDetails?.bank_name,
                    })
                }

                if (paystackCardDebit) {
                    dispatch({
                        type: SET_SUB_CARD_DETAILS,
                        payload: true,
                    })

                    dispatch({
                        type: SET_PAYMENT_TYPE,
                        payload: 'Card Debit',
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        repaymentDetails,
        showSubscriptionPlan?.tertiary,
        repaymentMethodType,
        accountDetails,
    ])

    const { mutateExistingCard, existingCardLoading } = useExistingCard({
        onSuccess: () => {
            dispatch({
                type: SET_SUBSCRIPTION_TENURE_MODAL,
                payload: false,
            })
            setShowSubTenure(false)
            setShowSubscriptionPlan({
                primary: false,
                secondary: false,
                tertiary: false,
            })
            dispatch({
                type: SET_SUB_PAYMENT_SUCCESS,
                payload: true,
            })
        },
    })

    const {
        showVerification,
        setShowVerification,
        handleOnBlur,
        handleInputChange,
        errors,
        accountName,
        accountNumber,
        bankDetails,
        setAccountNumber,
        setAccountName,
        setBankDetails,
        otp,
        setOtp,
        toastError,
        errorMessage,
        directDebitBanks,
        handleResend,
        setShowBankCard,
        showBankCard,
        handleSelectChange,
        validateMandateActivationFetching,
        setToastError,
        setErrorMessage,
        setDirectDebitBanks,
        userBankDetailsFetching,
        debitMandateFetching,
        requestMandateActivationFetching,
        debitMandateRefetch,
        printMandateFetching,
        printMandateRefetch,
        card,
        setCard,
        bankOtpResent,
        setBankOtpResent,
        validateMandateActivationRefetch,
        initializePaystackDirectdebitFetching,
        initializePaystackDirectDebitRefetch,
        initializeRecovaDirectdebitFetching,
        initializeRecovaDirectDebitRefetch,
    } = useDirectDebitRepaymentSetup({
        estimationId,
        showMandate: showMandate,
        closeMandate: closeMandate,
        closeNonMandate: closeNonMandate,
        closeSubModal: closeSubModal,
        recovaModal: recovaModal,
    })

    const resetSubModalState = () => {
        dispatch({
            type: SET_SUBSCRIPTION_TENURE_MODAL,
            payload: false,
        })
        setShowSubTenure(false)
        setShowSubscriptionPlan({
            primary: false,
            secondary: false,
            tertiary: false,
        })
        history.push({
            pathname: '/consumer/workspace',
            state: {
                isSubscription: false,
                isLeaseToOwn: false,
                isWithSuggestion: false,
            },
        })
        dispatch({
            type: SET_PAYMENT_TYPE,
            payload: '',
        })
        dispatch({
            type: SET_SUB_CARD_DETAILS,
            payload: false,
        })
        dispatch({
            type: SET_SUB_DIRECT_DETAILS,
            payload: false,
        })
        setAccountNumber('')
        setAccountName('')
        setBankDetails({
            bankCode: '',
            bankName: '',
        })
        setShowInstruction(false)
        setShowCardInstructionError(false)
        setShowRepaymentScheduleWarning(false)
    }

    const { refetchPaymentPlans, ssePaymentPlans } =
        useRetrieveSSEPaymentPlansData({
            estimationId: estimationId,
            enabled: estimationId?.length > 0 ? true : false,
            onSuccess: data => {
                dispatch({
                    type: PAYMENT_PLANS,
                    payload: data,
                })
                // check if any of the plan has been preselected
                const selectedPlanIndex = data.findIndex(
                    plan => plan.selected === true,
                )
                if (selectedPlanIndex !== -1) {
                    handledefaulSelectedPlans(selectedPlanIndex)
                }
            },
            onError: err => {
                errorAlert(err)
            },
        })
    const isRental =
        ssePaymentPlans?.data?.data?.condition?.descriptor.includes('rental')
    const isUpfrontDepositRequired =
        state?.selectedPaymentPlan?.credit_decision?.decision === 'MAYBE'

    useEffect(() => {
        if (estimationId && showPaymentPlan?.secondary === true) {
            refetchPaymentPlans()
        }
    }, [estimationId, refetchPaymentPlans, showPaymentPlan?.secondary])

    const { mutateSSESelectPaymentPlan, selectSSEPaymentPlanLoading } =
        useSelectSSEPaymentPlan({
            onSuccess: () => {
                refetchUpdates()
                handleShowUpfrontDeposit()
                history.push({
                    pathname: '/consumer/workspace',
                    state: { isLeaseToOwn: false },
                })
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sseSelectedPaymentPlanSuccessFul'].action,
                    eventTrackers['sseSelectedPaymentPlanSuccessFul'].label,
                    eventTrackers['sseSelectedPaymentPlanSuccessFul'].category,
                )
            },
        })

    const { mutateSendAgreementEmail } = useSendAgreementReminderEmail({})

    const handleSetNewDeposit = e => {
        if (
            e.target.value?.length > 0 &&
            /^[,0-9]+$/.test(e.target.value) === true
        ) {
            setNewDeposit(numberWithCommas(e.target.value))
            setNewDepositError('')
        } else if (e.target.value === '') {
            setNewDeposit('')
            setNewDepositError('')
        }
    }

    const minNewDeposit =
        (state?.selectedPaymentPlan?.credit_decision?.suggestions[0]?.value /
            100) *
        removeCommaFromNum(state?.selectedPaymentPlan?.solution_amount)

    const solutionAmount = removeCommaFromNum(
        state?.selectedPaymentPlan?.solution_amount,
    )

    const {
        mutateSSESimulatePaymentPlans,
        simulateSSEPaymentPlansLoading,
        simulateSSEPaymentPlansData,
    } = useSimulateSSEPaymentPlans({
        onSuccess: () => {
            handleShowPaymentDetails()
            setShowNewDeposit(false)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSimulateIncreasedUpfrontDeposit'].action,
                eventTrackers['sseSimulateIncreasedUpfrontDeposit'].label,
                eventTrackers['sseSimulateIncreasedUpfrontDeposit'].category,
            )
        },
    })

    const {
        mutateSelectSSEPaymentPlanWithUpfrontDeposit,
        selectSSEPaymentPlanWithUpfrontDepositLoading,
    } = useSelectSSEPaymentPlanWithUpfrontDeposit({
        onSuccess: () => {
            refetchUpdates()
            handleShowUpfrontDeposit()
            history.push({
                pathname: '/consumer/workspace',
                state: { isWithSuggestion: false },
            })
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSelectedPaymentPlanSuccessFul'].action,
                eventTrackers['sseSelectedPaymentPlanSuccessFul'].label,
                eventTrackers['sseSelectedPaymentPlanSuccessFul'].category,
            )
        },
    })

    const submitNewDeposit = () => {
        if (removeCommaFromNum(newDeposit) < minNewDeposit) {
            setNewDepositError(
                `Please enter an amount more than ₦${numberWithCommas(
                    minNewDeposit,
                )}`,
            )
        } else if (solutionAmount < removeCommaFromNum(newDeposit)) {
            setNewDepositError(
                `Please enter an amount less than ₦${numberWithCommas(
                    solutionAmount,
                )}`,
            )
        } else {
            mutateSSESimulatePaymentPlans({
                estimation_id: estimationId,
                upfront_deposit: removeCommaFromNum(newDeposit),
                selected_tenure: state?.selectedPaymentPlan?.tenure,
            })
            setNewDepositError('')
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseSubmitIncreasedUpfrontDeposit'].action,
                eventTrackers['sseSubmitIncreasedUpfrontDeposit'].label,
                eventTrackers['sseSubmitIncreasedUpfrontDeposit'].category,
            )
        }
    }

    const queryParams = new URLSearchParams(history?.location?.search)
    const pagaId = queryParams.get('code')
    const pagaCancelled = queryParams.get('error')
    const { submitPagaAuthCode } = useSubmitPagaAuth({
        onSuccess: () => {
            dispatch({
                type: SET_SUB_PAYMENT_SUCCESS,
                payload: true,
            })
            appTracking(
                '',
                '',
                '',
                'REPAYMENT_SETUP_DIRECT_DEBIT_PAGA_SUCCESSFUL',
                'REPAYMENT_SETUP_DIRECT_DEBIT_PAGA',
                'REPAYMENT_SETUP',
                ['MP', 'GA'],
                'event',
                {
                    event: {},
                },
            )
        },
        onError: err => {
            errorAlert(err)
        },
    })

    useEffect(() => {
        if (pagaId?.length > 0 && estimationId?.length > 0) {
            submitPagaAuthCode({
                authorization_code: pagaId,
                estimation_id: estimationId,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagaId, estimationId])

    useEffect(() => {
        if (pagaCancelled === 'cancelled') {
            history.push({
                pathname: '/consumer/workspace',
                state: {
                    isSubscription: false,
                    isLeaseToOwn: false,
                    isWithSuggestion: false,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagaCancelled])

    const handleShowInstruction = () => {
        setShowInstruction(true)
    }

    return (
        <>
            {/* Recova success modal  */}
            <ActionModal
                modalWidth="610px"
                onCancel={() => {
                    setShowRecovaSuccessModal(false)
                }}
                actionModalOpen={showRecovaSuccessModal}
                handlePrimaryBtnClick={() => {
                    setShowRecovaSuccessModal(false)
                }}
                title="Setup Direct Debit"
                primaryBtnText="Done"
                headerText="A link has been sent to you"
                subTitle="A link has been sent to your registered email address and phone number. Kindly click on the link to continue with your setup. Please do this within the next 7 days to avoid the link expiring."
                actionType="warning"
                v2
                showFooter
            />
            {/* LEASE TO OWN STARTS */}
            <SecondaryModal
                showModal={
                    state.showDealApprovalModal || showPaymentPlan?.primary
                }
                title="Your deal is approved!"
                primaryBtnText="Select payment plan"
                handlePrimaryBtnClick={handleShowPaymentPlans}
                onCancel={() => {
                    dispatch({
                        type: SET_DEAL_APPROVAL_MODAL,
                        payload: false,
                    })
                    setShowPaymentPlan({
                        primary: false,
                        secondary: false,
                    })
                    history.push({
                        pathname: '/consumer/workspace',
                        state: { isLeaseToOwn: false },
                    })
                }}
                content={
                    <div className={styles.modal_content_container}>
                        <div className={styles.lottie_animation}>
                            <Lottie options={animationOptions} />
                        </div>
                        <div>
                            <SuccessIcon />
                        </div>
                        <div>
                            <h3>Welcome to SunFi, {consumerFirstName}!</h3>
                            <p>
                                We are really excited to have you onboard on
                                SunFi and you are eligible for solar financing
                                by SunFi. Your next step is a payment plan
                            </p>
                        </div>
                    </div>
                }
                v2
                showFooter
            />
            <SecondaryModal
                modalWidth={isMobile ? 'auto' : '610px'}
                onCancel={() => {
                    dispatch({
                        type: SET_PAYMENT_PLAN_MODAL,
                        payload: false,
                    })
                    setShowPaymentPlan({
                        primary: false,
                        secondary: false,
                    })
                    history.push({
                        pathname: '/consumer/workspace',
                        state: { isLeaseToOwn: false, isWithSuggestion: false },
                    })
                    setShowNewDeposit(false)
                    setNewDeposit('')
                    setNewDepositError('')
                }}
                showModal={
                    state.showPaymentPlanModal || showPaymentPlan?.secondary
                }
                title={
                    state.showPaymentDetailsModal
                        ? 'Confirm your payment setup'
                        : 'Select your preferred payment plan'
                }
                primaryBtnText={showNewDeposit ? 'Continue' : 'Confirm my plan'}
                primaryBtnDisabled={
                    showPaymentPlan?.secondary === true &&
                    !state.selectedPaymentPlan
                        ? true
                        : showNewDeposit && newDeposit?.length === 0
                        ? true
                        : false
                }
                primaryBtnLoading={
                    state.showPaymentDetailsModal && selectSSEPaymentPlanLoading
                        ? true
                        : state.showPaymentDetailsModal &&
                          selectSSEPaymentPlanWithUpfrontDepositLoading
                        ? true
                        : showNewDeposit && simulateSSEPaymentPlansLoading
                        ? true
                        : false
                }
                handlePrimaryBtnClick={
                    state.showPaymentDetailsModal && !isUpfrontDepositRequired
                        ? () => {
                              mutateSSESelectPaymentPlan({
                                  estimation_id: estimationId,
                                  payment_plan_id:
                                      state?.selectedPaymentPlan?.id,
                              })
                              appTracking(
                                  pageUrlName,
                                  pageTracker,
                                  pageTitle,
                                  eventTrackers['sseConfirmSelectedPaymentPlan']
                                      .action,
                                  eventTrackers['sseConfirmSelectedPaymentPlan']
                                      .label,
                                  eventTrackers['sseConfirmSelectedPaymentPlan']
                                      .category,
                              )
                          }
                        : state.showPaymentDetailsModal &&
                          isUpfrontDepositRequired
                        ? () => {
                              mutateSelectSSEPaymentPlanWithUpfrontDeposit({
                                  estimation_id: estimationId,
                                  upfront_deposit:
                                      removeCommaFromNum(newDeposit),
                                  tenure: state?.selectedPaymentPlan?.tenure,
                              })
                              appTracking(
                                  pageUrlName,
                                  pageTracker,
                                  pageTitle,
                                  eventTrackers[
                                      'sseConfirmSelectedPaymentPlanWithNewUpfrontDeposit'
                                  ].action,
                                  eventTrackers[
                                      'sseConfirmSelectedPaymentPlanWithNewUpfrontDeposit'
                                  ].label,
                                  eventTrackers[
                                      'sseConfirmSelectedPaymentPlanWithNewUpfrontDeposit'
                                  ].category,
                              )
                          }
                        : isUpfrontDepositRequired &&
                          !state.showPaymentDetailsModal &&
                          !showNewDeposit
                        ? () => {
                              setShowNewDeposit(true)
                              appTracking(
                                  pageUrlName,
                                  pageTracker,
                                  pageTitle,
                                  eventTrackers['sseIncreaseUpfrontDeposit']
                                      .action,
                                  eventTrackers['sseIncreaseUpfrontDeposit']
                                      .label,
                                  eventTrackers['sseIncreaseUpfrontDeposit']
                                      .category,
                              )
                          }
                        : showNewDeposit && !state.showPaymentDetailsModal
                        ? submitNewDeposit
                        : handleShowPaymentDetails
                }
                content={
                    <>
                        {state.showPaymentDetailsModal ? (
                            <div className={styles.payment_plan_details}>
                                <div
                                    className={styles.go_back}
                                    onClick={() => {
                                        dispatch({
                                            type: SET_PAYMENT_DETAILS_MODAL,
                                            payload: false,
                                        })
                                        if (isWithSuggestion) {
                                            setShowNewDeposit(false)
                                            setNewDeposit('')
                                        }
                                    }}
                                >
                                    <Goback />
                                    <p>Go back to available plans</p>
                                </div>
                                <div className={styles.upfront_deposit_field}>
                                    <div>
                                        <p>UPFRONT DEPOSIT OF</p>
                                        <h3 className={styles.upfront_amount}>
                                            <NairaSymbol />{' '}
                                            {simulateSSEPaymentPlansData?.data
                                                ?.data?.initial_deposit ||
                                                state.selectedPaymentPlan
                                                    ?.initial_deposit}
                                        </h3>
                                    </div>
                                </div>
                                <div className={styles.payment_breakdown}>
                                    <section>
                                        <h3>MONTHLY PAYMENT</h3>
                                        <p>
                                            <NairaSymbol />{' '}
                                            {simulateSSEPaymentPlansData?.data
                                                ?.data?.monthly_payment ||
                                                state.selectedPaymentPlan
                                                    ?.monthly_payment}
                                        </p>
                                    </section>
                                    <section>
                                        <h3>DURATION</h3>
                                        <p>
                                            {simulateSSEPaymentPlansData?.data
                                                ?.data?.tenure ||
                                                state.selectedPaymentPlan
                                                    ?.tenure}{' '}
                                            Months
                                        </p>
                                    </section>
                                    {/* Start date and end date is not required in this case */}
                                    {/* <section>
                                        <h3>START DATE</h3>
                                        <p>13 Jun, 2022</p>
                                    </section>
                                    <section>
                                        <h3>END DATE</h3>
                                        <p>13 Jun, 2025</p>
                                    </section> */}
                                </div>
                            </div>
                        ) : showNewDeposit ? (
                            <div
                                className={styles.payment_plan_details}
                                style={{ minHeight: '500px' }}
                            >
                                <div className={styles.go_back}>
                                    <BackNav
                                        title={'Go back to available plans'}
                                        onClick={() => {
                                            setShowNewDeposit(false)
                                            setNewDeposit('')
                                            setNewDepositError('')
                                        }}
                                        v2
                                    />
                                </div>
                                <p className={styles.newdeposit_label}>
                                    Enter an amount that is not less than{' '}
                                    <span>
                                        <NairaSymbol />
                                        {numberWithCommas(minNewDeposit)}
                                    </span>
                                </p>
                                <IconInputField
                                    icon={NairaIcon}
                                    name="new_deposit"
                                    title={'Enter new upfront deposit'}
                                    value={newDeposit}
                                    type={'text'}
                                    width={'100%'}
                                    handleChange={handleSetNewDeposit}
                                    errorMessage={newDepositError}
                                />
                            </div>
                        ) : (
                            <div
                                className={`${styles.payment_plan_container} payment_plans_style`}
                            >
                                {state.paymentPlans.map((el, idx) => (
                                    <div
                                        key={idx}
                                        className={styles.payment_plan}
                                    >
                                        {el?.credit_decision?.decision ===
                                        'MAYBE' ? (
                                            <div
                                                className={
                                                    styles.depositinfo_container
                                                }
                                            >
                                                <WarningInfoLarge />
                                                <p>
                                                    Increase your upfront
                                                    deposit to access this plan,
                                                    and pay as low as
                                                </p>
                                            </div>
                                        ) : null}
                                        <div className={styles.plan_details}>
                                            <div>
                                                <h3> UPFRONT DEPOSIT</h3>
                                                <p>
                                                    <NairaSymbol />
                                                    {el?.initial_deposit}
                                                </p>
                                            </div>
                                            <div>
                                                <h3>MONTHS OF REPAYMENT</h3>
                                                <p>{el.tenure} Months</p>
                                            </div>
                                        </div>
                                        <div className={styles.select_plan}>
                                            <div
                                                className={
                                                    styles.monthly_payment_section
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.monthly_payment
                                                    }
                                                >
                                                    <NairaSymbol />
                                                    {el.monthly_payment}
                                                </p>{' '}
                                                <span>/ month</span>
                                            </div>
                                            {el.selected ? (
                                                <div
                                                    className={styles.selected}
                                                    onClick={() => {
                                                        handleSelectPlans(idx)
                                                        appTracking(
                                                            pageUrlName,
                                                            pageTracker,
                                                            pageTitle,
                                                            eventTrackers[
                                                                'sseUnselectPaymentPlan'
                                                            ].action,
                                                            eventTrackers[
                                                                'sseUnselectPaymentPlan'
                                                            ].label,
                                                            eventTrackers[
                                                                'sseUnselectPaymentPlan'
                                                            ].category,
                                                            ['MP', 'GA'],
                                                            'event',
                                                            { el },
                                                        )
                                                    }}
                                                >
                                                    <CircleMinus /> Plan
                                                    selected
                                                </div>
                                            ) : (
                                                <button
                                                    className={
                                                        styles.select_plan_button
                                                    }
                                                    onClick={() => {
                                                        handleSelectPlans(idx)
                                                        appTracking(
                                                            pageUrlName,
                                                            pageTracker,
                                                            pageTitle,
                                                            eventTrackers[
                                                                'sseSelectPaymentPlan'
                                                            ].action,
                                                            eventTrackers[
                                                                'sseSelectPaymentPlan'
                                                            ].label,
                                                            eventTrackers[
                                                                'sseSelectPaymentPlan'
                                                            ].category,
                                                            ['MP', 'GA'],
                                                            'event',
                                                            { el },
                                                        )
                                                    }}
                                                >
                                                    <CheckMark /> Select Plan
                                                </button>
                                            )}
                                        </div>
                                        {/* insurance cost */}
                                        {process.env
                                            .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                                            'true' &&
                                            el?.investment_meta_data
                                                ?.monthly_insurance_amount && (
                                                <div
                                                    className={
                                                        styles.insurance_cost
                                                    }
                                                >
                                                    <p>Insurance amount of</p>
                                                    <CustomToolTip
                                                        text={
                                                            'We have added an insurance fee to each plan listed under this package.'
                                                        }
                                                        toolTipPopupWidth={174}
                                                        toolTipMarginLeft={-100}
                                                        toolTipPadding={
                                                            '9px 10px 25px 10px'
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.insurance_cost_right
                                                            }
                                                        >
                                                            <span>
                                                                <NairaSymbol />
                                                                {
                                                                    el
                                                                        ?.investment_meta_data
                                                                        ?.monthly_insurance_amount
                                                                }
                                                            </span>
                                                            <img
                                                                src={infoIcon}
                                                                alt={
                                                                    'info icon'
                                                                }
                                                            />
                                                        </div>
                                                    </CustomToolTip>
                                                </div>
                                            )}
                                    </div>
                                ))}
                                {isRental ? (
                                    <div className={styles.payment_plan}>
                                        <div
                                            className={
                                                styles.moreoffer_container_one
                                            }
                                        >
                                            <WarningInfoLarge />
                                            <p>
                                                Still can’t see any payment plan
                                                that interest you?
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                styles.moreoffer_container_two
                                            }
                                        >
                                            <p>
                                                Check out some of the other
                                                available offers available just
                                                for you
                                            </p>
                                            <ConsumerButton
                                                btnWidth={'124px'}
                                                btnHeight={'44px'}
                                                btnBgColor={'#004AAD'}
                                                btnTextColor={'#ffffff'}
                                                btnFontSize={'13px'}
                                                handleClick={() => {
                                                    setOtherOffers(true)
                                                    setShowPaymentPlan({
                                                        primary: false,
                                                        secondary: false,
                                                    })
                                                }}
                                            >
                                                See other offers
                                            </ConsumerButton>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </>
                }
                v2
                showFooter
            />
            {/* LEASE TO OWN ENDS*/}

            {/* SUBSCRIPTION STARTS */}
            <SecondaryModal
                showModal={
                    state.showDealApprovalModalSub ||
                    showSubscriptionPlan?.primary
                }
                title="Your subscription plan has been approved!"
                primaryBtnText={
                    updates[0]?.category === 'upfront_deposit'
                        ? 'Pay now'
                        : 'Set up now'
                }
                footerLeftContent="chat"
                handlePrimaryBtnClick={handleSelectSubscriptionTenure}
                onCancel={() => {
                    dispatch({
                        type: SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION,
                        payload: false,
                    })
                    setShowSubscriptionPlan({
                        primary: false,
                        secondary: false,
                    })
                    setShowSubTenure(false)
                    history.push({
                        pathname: '/consumer/workspace',
                        state: { isSubscription: false },
                    })
                }}
                content={
                    <div className={styles.modal_content_container}>
                        <div className={styles.lottie_animation}>
                            <Lottie options={animationOptions} />
                        </div>
                        <div>
                            <SuccessIcon />
                        </div>
                        <div>
                            <h3>
                                Great work, {consumerFirstName}! Set up your
                                subscription plan
                            </h3>
                            <p>
                                Your subscription plan has been confirmed and
                                you can go ahead to Setting up your subscription
                                plan & making your first payment
                            </p>
                        </div>
                    </div>
                }
                v2
                showFooter
            />

            <ConsumerModalBase
                standardWidth={
                    showInstruction || showRepaymentScheduleWarning
                        ? '476px'
                        : '610px'
                }
                mobileHeight={576}
                onCancel={resetSubModalState}
                showModal={
                    state.showSubscriptionTenureModal ||
                    showSubscriptionPlan?.secondary ||
                    (showSubscriptionPlan?.tertiary &&
                        (paystackDirectDebit ||
                            paystackCardDebit ||
                            remitaDirectDebit ||
                            recovaDirectDebit))
                }
                title={
                    repaymentDetails()?.hasTenure === false &&
                    state.showSubscriptionDetailsModal
                        ? 'Confirm your Subscription setup'
                        : state.showPaymentSetup
                        ? 'Select Payment Type'
                        : showInstruction
                        ? 'Add a debit card'
                        : state.paymentType === 'Card Debit'
                        ? 'Select a card below to pay with'
                        : state.paymentType === 'Direct Debit'
                        ? 'Setup Direct Debit'
                        : state.showDirectDebitTypes
                        ? 'Select direct debit type'
                        : state.showSubDirectDetails
                        ? 'Setup Direct Debit'
                        : state.showSubscriptionTenureModal || showSubTenure
                        ? 'Select subscription plan'
                        : null
                }
                primaryBtnText={
                    repaymentDetails()?.hasTenure === false &&
                    state.showSubscriptionDetailsModal
                        ? 'Confirm your plan'
                        : state.isMandate
                        ? 'Download Mandate'
                        : showCardInstructionError ||
                          showRepaymentScheduleWarning
                        ? 'Got it'
                        : 'Continue'
                }
                primaryBtnDisabled={
                    repaymentDetails()?.hasTenure === false &&
                    state.subscriptionTenure === '' &&
                    (state.showSubscriptionTenureModal || showSubTenure)
                        ? true
                        : state.showPaymentSetup && state.paymentType === ''
                        ? true
                        : state.showSubCardDetails &&
                          selectedCard === false &&
                          !showInstruction
                        ? true
                        : state.showDirectDebitTypes &&
                          state.selectedDirectDebitType === ''
                        ? true
                        : false
                }
                primaryBtnLoading={
                    state.showSubscriptionDetailsModal === true &&
                    sseRepaymentTenureLoading
                        ? true
                        : state.showSubCardDetails && existingCardLoading
                        ? true
                        : state.showSubDirectDetails &&
                          (userBankDetailsFetching ||
                              debitMandateFetching ||
                              requestMandateActivationFetching ||
                              initializePaystackDirectdebitFetching ||
                              initializeRecovaDirectdebitFetching)
                        ? true
                        : state.isMandate && printMandateFetching
                        ? true
                        : false
                }
                handlePrimaryBtnClick={
                    state.showSubscriptionDetailsModal
                        ? () => {
                              submitSSERepaymentTenure({
                                  estimation_id: estimationId,
                                  tenure: payloadTenure,
                              })
                          }
                        : state.showPaymentSetup &&
                          state.paymentType === 'Card Debit'
                        ? handleCardDebitSetup
                        : state.showPaymentSetup &&
                          state.paymentType === 'Direct Debit'
                        ? handleDirectDebitSetup
                        : state.showPaymentSetup &&
                          state.paymentType === 'Pay with Paga'
                        ? handlePagaSetup
                        : showInstruction && !showCardInstructionError
                        ? () =>
                              dispatch({
                                  type: SET_PAYSTACK_MODAL,
                                  payload: true,
                              })
                        : showCardInstructionError ||
                          showRepaymentScheduleWarning
                        ? () => resetSubModalState()
                        : state.showDirectDebitTypes &&
                          state.selectedDirectDebitType !== ''
                        ? () => handleDisplayDirectDebitPage()
                        : state.showSubDirectDetails
                        ? () => {
                              state.selectedDirectDebitType === 'paystack'
                                  ? initializePaystackDirectDebitRefetch()
                                  : state.selectedDirectDebitType ===
                                        'remita' && canSelectDirectDebit
                                  ? debitMandateRefetch()
                                  : state.selectedDirectDebitType === 'recova'
                                  ? initializeRecovaDirectDebitRefetch()
                                  : setShowRepaymentScheduleWarning(true)
                          }
                        : state.showSubCardDetails
                        ? () => {
                              mutateExistingCard(estimationId)
                          }
                        : state.isMandate
                        ? () => {
                              printMandateRefetch()
                              appTracking(
                                  pageUrlName,
                                  pageTracker,
                                  pageTitle,
                                  eventTrackers['printMandate'].action,
                                  eventTrackers['printMandate'].label,
                                  eventTrackers['printMandate'].category,
                              )
                          }
                        : state.showSubscriptionTenureModal || showSubTenure
                        ? handleShowSubscriptionDetails
                        : null
                }
                handleSecondaryBtnClick={() => {
                    if (showInstruction) {
                        setShowCardInstructionError(true)
                    }
                }}
                footerLeftContent={
                    showInstruction && !showCardInstructionError
                        ? 'button'
                        : 'chat'
                }
                secondaryBtnText="I don’t have a card"
            >
                {state.showSubscriptionDetailsModal &&
                repaymentDetails()?.hasTenure === false ? (
                    <div className={styles.payment_plan_details_two}>
                        <div className={styles.go_back}>
                            <BackNav
                                title={'Go Back'}
                                onClick={() => {
                                    dispatch({
                                        type: SET_SUBSCRIPTION_DETAILS_MODAL,
                                        payload: false,
                                    })
                                }}
                                v2
                            />
                        </div>
                        <div className={styles.upfront_deposit_field}>
                            <div>
                                <p>Monthly payment of</p>
                                <h3 className={styles.upfront_amount}>
                                    <NairaSymbol />{' '}
                                    {repaymentDetails()?.monthly_payment}
                                </h3>
                            </div>
                        </div>
                        <div className={styles.payment_breakdown_sub}>
                            <section>
                                <h3>
                                    START DATE <WarningInfo />
                                </h3>
                                <p>
                                    {repaymentDetails()?.start_date !==
                                    undefined
                                        ? format(
                                              new Date(
                                                  repaymentDetails()?.start_date,
                                              ),
                                              'd MMM, yyyy',
                                          )
                                        : ''}
                                </p>
                            </section>
                            <section>
                                <div>
                                    <h3>
                                        Payment Duration <WarningInfo />
                                    </h3>
                                    <p>
                                        {state.subscriptionTenure === '' ||
                                        state.subscriptionTenure !== 'flexible'
                                            ? state.subscriptionTenure
                                            : 'Flexible Plan'}
                                    </p>
                                </div>
                            </section>
                        </div>
                    </div>
                ) : state.showPaymentSetup &&
                  process.env
                      .REACT_APP_ENABLE_PAGA_FOR_REPAYMENT_METHOD_SETUP ===
                      'true' ? (
                    <div className={styles.payment_plan_details_two}>
                        <div>
                            <h2 className={styles.repayment_title}>
                                How would you like to make payment?
                            </h2>
                            <p className={styles.repayment_sub_title}>
                                Choose a preferred payment type from the options
                                below.
                            </p>
                            <div className={styles.repayment_type_wrapper}>
                                {paymentTypeList().map(type => (
                                    <div
                                        className={styles.repayment_type_child}
                                        key={type.id}
                                    >
                                        <input
                                            type="radio"
                                            id={type.title}
                                            value={type.title}
                                            name="repayment-type"
                                            onChange={e => {
                                                handlePaymentType(
                                                    e.target.value,
                                                )
                                            }}
                                        />
                                        <label htmlFor={type.title}>
                                            <div>
                                                <div>{type.icon}</div>
                                                <div>
                                                    <h2>{type.title}</h2>
                                                    <p>{type.subTitle}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <CheckIcon />
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div>
                                    
                                </div> */}
                    </div>
                ) : state.showPaymentSetup &&
                  process.env
                      .REACT_APP_ENABLE_PAGA_FOR_REPAYMENT_METHOD_SETUP !==
                      'true' ? (
                    <div className={styles.payment_plan_details_two}>
                        <p className={styles.select_tenure}>
                            Choose a preferred payment type below
                        </p>
                        <SelectField
                            name="paymentType"
                            initialOption="Select Preferred Payment Type"
                            selectWidth={'100%'}
                            floatingLabel={'Select Preferred Payment Type'}
                            onChange={handlePaymentType}
                            withCheckBox={true}
                            floatLabelOnSelectedValue
                            dropdownPositionRelative
                            type="direct-debit"
                            selectedValue={state.paymentType}
                            currentSelected={state.paymentType}
                            values={paymentTypes()?.map(option => ({
                                value: option.value,
                                disabled: option.disabled,
                            }))}
                            value={state.paymentType}
                        />
                    </div>
                ) : state.showSubCardDetails ? (
                    <>
                        {showInstruction ? (
                            <CardInstructions
                                title={
                                    showCardInstructionError
                                        ? 'You need a debit card linked to your submitted account to continue'
                                        : 'Ensure your debit card bank is the same as the primary bank you submitted your bank statement from'
                                }
                                subTitle={
                                    showCardInstructionError
                                        ? 'For us to be able to add your card, please get a debit card linked to the same bank'
                                        : 'For us to be able to add your card, please ensure your debit card bank is the same as bank you submitted bank statement from'
                                }
                                showCardInstructionError={
                                    showCardInstructionError
                                }
                                accountDetails={accountDetails}
                            />
                        ) : (
                            <div className={styles.payment_plan_details_two}>
                                {process.env
                                    .REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT !==
                                    'true' && (
                                    <div className={styles.go_back}>
                                        <BackNav
                                            title={'Go Back'}
                                            onClick={() => {
                                                dispatch({
                                                    type: SET_PAYMENT_SETUP,
                                                    payload: true,
                                                })
                                                dispatch({
                                                    type: SET_SUB_CARD_DETAILS,
                                                    payload: false,
                                                })
                                                dispatch({
                                                    type: SET_SUBSCRIPTION_DETAILS_MODAL,
                                                    payload: false,
                                                })
                                                setShowSubTenure(false)
                                                setShowSubscriptionPlan({
                                                    primary: false,
                                                    secondary: false,
                                                    tertiary: true,
                                                })
                                            }}
                                            v2
                                        />
                                    </div>
                                )}
                                <CardDetailsKyc
                                    estimationId={estimationId}
                                    email={consumerEmail}
                                    selectedCard={selectedCard}
                                    setSelectedCard={setSelectedCard}
                                    onSuccess={closeNonMandate}
                                    handleShowInstruction={
                                        handleShowInstruction
                                    }
                                />
                            </div>
                        )}
                    </>
                ) : state.showDirectDebitTypes ? (
                    <>
                        <div className={styles.payment_plan_details_two}>
                            <div>
                                <h2
                                    className={styles.repayment_title}
                                    style={{
                                        fontSize: '14px',
                                        color: '#011A3C',
                                    }}
                                >
                                    How would you like to make payment?
                                </h2>
                                <p
                                    className={styles.repayment_sub_title}
                                    style={{ color: '#4D4D4D' }}
                                >
                                    Choose a preferred payment type from the
                                    options below.
                                </p>
                                <div className={styles.repayment_type_wrapper}>
                                    {directDebitTypeList(
                                        repaymentMethodType,
                                    ).map(type => (
                                        <div
                                            className={
                                                styles.repayment_type_child
                                            }
                                            key={type.id}
                                        >
                                            <input
                                                type="radio"
                                                id={type.title}
                                                value={type.value}
                                                name="direct-debit-type"
                                                onChange={e => {
                                                    handleDirectDebitType(
                                                        e.target.value,
                                                    )
                                                }}
                                            />
                                            <label htmlFor={type.title}>
                                                <div>
                                                    <div>{type.icon}</div>
                                                    <div>
                                                        <h2>{type.title}</h2>
                                                        <p>{type.subTitle}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <CheckIcon />
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                ) : state.showSubDirectDetails ? (
                    <>
                        {showRepaymentScheduleWarning ? (
                            <CardInstructions
                                title={'Awaiting repayment schedule'}
                                subTitle={
                                    "A repayment schedule is yet to be set up for your account. Once this has been setup, you'll receive an email to continue this process. For further details contact us."
                                }
                                showRepaymentScheduleWarning={
                                    showRepaymentScheduleWarning
                                }
                            />
                        ) : (
                            <div className={styles.payment_plan_details}>
                                {process.env
                                    .REACT_APP_SHOW_DIRECT_DEBIT_AS_DEFAULT ===
                                'true' ? (
                                    <div className={styles.go_back}>
                                        <BackNav
                                            title={'Go Back'}
                                            onClick={() => {
                                                dispatch({
                                                    type: SET_DIRECT_DEBIT_TYPES,
                                                    payload: true,
                                                })
                                                dispatch({
                                                    type: SET_SUB_DIRECT_DETAILS,
                                                    payload: false,
                                                })
                                                dispatch({
                                                    type: SET_SUBSCRIPTION_DETAILS_MODAL,
                                                    payload: false,
                                                })
                                            }}
                                            v2
                                            marginTop={'0px !important'}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.go_back}>
                                        <BackNav
                                            title={'Go Back'}
                                            onClick={() => {
                                                dispatch({
                                                    type: SET_PAYMENT_SETUP,
                                                    payload: true,
                                                })
                                                dispatch({
                                                    type: SET_SUB_DIRECT_DETAILS,
                                                    payload: false,
                                                })
                                                dispatch({
                                                    type: SET_SUBSCRIPTION_DETAILS_MODAL,
                                                    payload: false,
                                                })
                                                setShowSubTenure(false)
                                                setShowSubscriptionPlan({
                                                    primary: false,
                                                    secondary: false,
                                                    tertiary: true,
                                                })
                                                setAccountNumber('')
                                                setAccountName('')
                                                setBankDetails({
                                                    bankCode: '',
                                                    bankName: '',
                                                })
                                            }}
                                            v2
                                        />
                                    </div>
                                )}
                                <SetupDirectDebitKyc
                                    estimationId={estimationId}
                                    handleOnBlur={handleOnBlur}
                                    handleInputChange={handleInputChange}
                                    errors={errors}
                                    accountName={accountName}
                                    accountNumber={accountNumber}
                                    bankDetails={bankDetails}
                                    toastError={toastError}
                                    errorMessage={errorMessage}
                                    directDebitBanks={directDebitBanks}
                                    handleSelectChange={handleSelectChange}
                                    setToastError={setToastError}
                                    setErrorMessage={setErrorMessage}
                                    setDirectDebitBanks={setDirectDebitBanks}
                                    accountDetails={accountDetails}
                                    directDebitType={state?.selectedDirectDebitType.toUpperCase()}
                                />
                            </div>
                        )}
                    </>
                ) : state.isMandate ? (
                    <div className={styles.payment_plan_details_two}>
                        <div className={styles.go_back}>
                            <BackNav
                                title={'Go Back'}
                                onClick={() => {
                                    dispatch({
                                        type: SET_DIRECT_MANDATE,
                                        payload: false,
                                    })

                                    dispatch({
                                        type: SET_SUB_DIRECT_DETAILS,
                                        payload: true,
                                    })
                                }}
                                v2
                            />
                        </div>
                        <div className={styles.mandate_wrapper}>
                            <p>
                                The bank you have selected requires you to fill
                                a mandate before you can use the direct debit
                                feature.
                            </p>
                            <div>
                                <div>
                                    <MandateIcon />
                                    <p>
                                        Powered by{' '}
                                        <img
                                            src={RemitaLogo}
                                            style={{
                                                marginLeft: '5px',
                                                marginTop: '-5px',
                                            }}
                                            alt="remita-logo"
                                        />
                                    </p>
                                </div>
                                <div>
                                    <h2>How to use the mandate</h2>
                                    <div>
                                        <OneIcon />
                                        <p>Download the mandate</p>
                                    </div>
                                    <div>
                                        <TwoIcon />
                                        <p>
                                            Fill the mandate with the required
                                            details
                                        </p>
                                    </div>
                                    <div>
                                        <ThreeIcon />
                                        <p>
                                            Take the filled mandate to submit at
                                            your bank
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : state.showSubscriptionTenureModal ||
                  (repaymentDetails()?.hasTenure === false && showSubTenure) ? (
                    <div className={styles.payment_plan_container_two}>
                        <div>
                            <p className={styles.select_tenure}>
                                Enter the number of month you want to subscribe
                                for
                            </p>
                            {state.subscriptionTenure === '' ||
                            state.subscriptionTenure !== 'flexible' ? (
                                <SelectField
                                    name="subscription_tenure"
                                    selectWidth={'100%'}
                                    floatingLabel={'Subscription plan'}
                                    initialOption={'Subscription plan'}
                                    dropdownPositionRelative
                                    floatLabelOnSelectedValue
                                    withCheckBox={true}
                                    value={state.subscriptionTenure}
                                    selectedValue={state.subscriptionTenure}
                                    currentSelected={state.subscriptionTenure}
                                    values={paymentPlanTenure.map(option => ({
                                        value: option,
                                    }))}
                                    onChange={handleSelectTenure}
                                />
                            ) : (
                                <div className={styles.flexible_tenure_wrapper}>
                                    <span>
                                        <CalendarIcon />
                                        Use a flexible subscription plan
                                    </span>
                                    <span onClick={handleFlexibleTenure}>
                                        <CancelIcon />
                                    </span>
                                </div>
                            )}
                            <div className={styles.switch_tenure_btn_wrapper}>
                                <button onClick={handleFlexibleTenure}>
                                    {state.subscriptionTenure === '' ||
                                    state.subscriptionTenure !== 'flexible'
                                        ? 'Use a flexible plan'
                                        : 'Choose a specific plan'}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </ConsumerModalBase>

            <SecondaryModal
                showModal={showVerification || showBankCard}
                onCancel={() => {
                    setShowVerification(false)
                    setShowBankCard(false)
                    resetSubModalState()
                }}
                modalWidth={'475px'}
                title={`Verify your Bank`}
                primaryBtnText={`Confirm`}
                handlePrimaryBtnClick={() => {
                    if (showVerification) {
                        setShowVerification(false)
                        setShowBankCard(true)
                    }
                    if (showBankCard) {
                        validateMandateActivationRefetch()
                    }
                }}
                primaryBtnLoading={validateMandateActivationFetching}
                showFooter
                primaryBtnDisabled={
                    showVerification &&
                    otp.join(',').replaceAll(',', '').length < 4
                        ? true
                        : showBankCard &&
                          card.join(',').replaceAll(',', '').length < 4
                        ? true
                        : false
                }
                v2
                footerLeftContent={showBankCard ? 'button' : null}
                secondaryBtnText="Previous"
                handleSecondaryBtnClick={() => {
                    setShowVerification(true)
                    setShowBankCard(false)
                }}
                kycSecondaryButtonStopLoading
                secondaryBtnDisabled={validateMandateActivationFetching}
                content={
                    <>
                        {toastError && (
                            <Toast
                                messageType={'error-secondary'}
                                message={errorMessage}
                            />
                        )}
                        {showVerification ? (
                            <Verify
                                otp={otp}
                                type="bank"
                                setOtp={setOtp}
                                handleResendOtp={handleResend}
                                resent={bankOtpResent}
                                setResent={setBankOtpResent}
                            />
                        ) : (
                            <Verify otp={card} type="card" setOtp={setCard} />
                        )}
                    </>
                }
            />

            <SecondaryModal
                showModal={state.subPaymentSuccess}
                title="Repayment Setup Successful"
                primaryBtnText="Done"
                footerLeftContent="chat"
                handlePrimaryBtnClick={() => {
                    dispatch({
                        type: SET_SUB_PAYMENT_SUCCESS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_SUB_DIRECT_DETAILS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_SUB_CARD_DETAILS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_PAYMENT_SETUP,
                        payload: false,
                    })
                    dispatch({
                        type: SET_PAYMENT_TYPE,
                        payload: '',
                    })
                    dispatch({
                        type: SET_SUBSCRIPTION_TENURE,
                        payload: '',
                    })
                    history.push({
                        pathname: '/consumer/workspace',
                        state: {
                            isSubscription: false,
                            isLeaseToOwn: false,
                            isWithSuggestion: false,
                        },
                    })
                    refetchUpdates()
                }}
                onCancel={() => {
                    dispatch({
                        type: SET_SUB_PAYMENT_SUCCESS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_SUB_DIRECT_DETAILS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_SUB_CARD_DETAILS,
                        payload: false,
                    })
                    dispatch({
                        type: SET_PAYMENT_SETUP,
                        payload: false,
                    })
                    dispatch({
                        type: SET_PAYMENT_TYPE,
                        payload: '',
                    })
                    dispatch({
                        type: SET_SUBSCRIPTION_TENURE,
                        payload: '',
                    })
                    history.push({
                        pathname: '/consumer/workspace',
                        state: {
                            isSubscription: false,
                            isLeaseToOwn: false,
                            isWithSuggestion: false,
                        },
                    })
                    refetchUpdates()
                }}
                content={
                    <div className={styles.modal_content_container}>
                        <div className={styles.lottie_animation}>
                            <Lottie options={animationOptions} />
                        </div>
                        <div>
                            <SuccessIcon />
                        </div>
                        <div>
                            <h3>Your repayment method setup was successful</h3>
                            <p>
                                We are really excited to have you onboard on
                                SunFi and you’re eligible for solar financing by
                                SunFi. Your next step is to select a payment
                                plan
                            </p>
                        </div>
                    </div>
                }
                v2
                showFooter
            />
            {/* SUBSCRIPTION ENDS */}

            {/* YES WITH SUGGESTION (SWITCH TO RENTAL) STARTS */}
            {otherOffers && (
                <DecisionIsMaybe
                    maybe={true}
                    toAvailablePlans={() => {
                        setOtherOffers(false)
                        setShowPaymentPlan({
                            primary: false,
                            secondary: true,
                        })
                    }}
                />
            )}

            {/* YES WITH SUGGESTION (SWITCH TO RENTAL) ENDS */}

            <ActionModal
                modalWidth="610px"
                onCancel={() => {
                    dispatch({
                        type: SET_UPFRONT_DEPOSIT_MODAL,
                        payload: false,
                    })
                }}
                actionModalOpen={state.showUpfrontDepositModal}
                handlePrimaryBtnClick={handleShowUpfrontDepositModal}
                title="Payment plan confirmed"
                primaryBtnText={
                    showAgreementModal ? 'Next Step' : 'Pay upfront deposit'
                }
                headerText="Your payment plan has been confirmed successfully"
                subTitle="We are really excited to have you onboard on SunFi and you are eligible for solar financing by SunFi. 
        Your next step is to pay an upfront deposit"
                actionType="success"
                v2
                showFooter
                primaryBtnLoading={state.isPaystackingLoading}
            />

            <SecondaryModal
                modalWidth="610px"
                showModal={state.isCardDetailsModal}
                modalHeight="400px"
                title="Confirm your payment setup"
                primaryBtnText="Confirm my plan"
                onCancel={() => {
                    dispatch({
                        type: SET_CARD_DETAILS,
                        payload: false,
                    })
                }}
                content={
                    <CardDetails
                        closeCardDetailsModal={() =>
                            dispatch({ type: SET_CARD_DETAILS, payload: false })
                        }
                        openCardDetailsModal={() =>
                            dispatch({ type: SET_CARD_DETAILS, payload: false })
                        }
                        estimationId={estimationId}
                        email={consumerEmail}
                        setVerifyLoading={e => {
                            dispatch({
                                type: SET_PAYSTACK_VERIFYING,
                                payload: e,
                            })
                        }}
                    />
                }
                v2
                showFooter={false}
            />
            {(state.showPaystackModal || showPaystackModal) && (
                <PayStack
                    estimationId={estimationId}
                    reloadPage={refetchUpdates}
                    event={
                        showInstruction
                            ? 'repayment_method_setup'
                            : 'upfront_deposit'
                    }
                    email={consumerEmail}
                    handleLoading={() => {
                        dispatch({
                            type: SET_PAYSTACK_LOADING,
                            payload: true,
                        })
                    }}
                    openCardDetailsModal={openCardDetailsModal}
                    handleError={() => errorAlert('Paystack error occurred')}
                    callInitializePayment={callInitializePayment}
                    setVerifyLoading={() => {
                        dispatch({
                            type: SET_PAYSTACK_VERIFYING,
                            payload: true,
                        })
                    }}
                    closePaystack={() => {
                        dispatch({
                            type: SET_PAYSTACK_MODAL,
                            payload: false,
                        })
                        setShowPaystackModal(false)
                    }}
                    onSuccess={
                        showInstruction ? () => closeNonMandate() : false
                    }
                    v2
                    onCardSaved={() => {
                        history.push({
                            pathname: '/consumer/workspace',
                            state: {
                                isSubscription: false,
                            },
                        })
                    }}
                />
            )}

            {/* AGREEMENT SIGNING */}

            <ActionModal
                modalWidth="610px"
                onCancel={() => {
                    dispatch({
                        type: SET_AGREEMENT_SIGNING_MODAL,
                        payload: false,
                    })
                }}
                actionModalOpen={state.showAgreementSigningModal}
                handlePrimaryBtnClick={() => {
                    dispatch({
                        type: SET_AGREEMENT_SIGNING_MODAL,
                        payload: false,
                    })
                }}
                handleSecondaryBtnClick={() => {
                    mutateSendAgreementEmail({ estimationId })

                    dispatch({
                        type: SET_AGREEMENT_SIGNING_MODAL,
                        payload: false,
                    })

                    dispatch({
                        type: SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL,
                        payload: true,
                    })
                }}
                title="Sign your agreement"
                primaryBtnText="Got it"
                footerLeftContent="button"
                secondaryBtnText="I already signed it"
                headerText="Next steps: Agreement signing"
                subTitle="This agreement allows us to seal this deal. Someone from our team will reach out with the details on how to sign your agreement manually. Once your signed agreement is confirmed, you’ll receive an email to proceed to make your upfront deposit."
                actionType="sign-agreement"
                subTitleMaxWidth="350px"
                v2
                showFooter
            />
            <ActionModal
                modalWidth="610px"
                onCancel={() => {
                    if (showAgreementSigningConfirmationModal) {
                        setShowAgreementSigningConfirmationModal(false)
                    } else {
                        dispatch({
                            type: SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL,
                            payload: false,
                        })
                    }
                }}
                actionModalOpen={
                    showAgreementSigningConfirmationModal ||
                    state.showAgreementSigningConfirmationModal
                }
                handlePrimaryBtnClick={() => {
                    if (showAgreementSigningConfirmationModal) {
                        setShowAgreementSigningConfirmationModal(false)
                    } else {
                        dispatch({
                            type: SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL,
                            payload: false,
                        })
                    }
                }}
                title="Sign your agreement"
                primaryBtnText="Got it"
                headerText="We need to confirm your binding agreement"
                subTitle="To continue to pay your upfront deposit, we’ll need to confirm if your have signed your agreement. Once we have confirmed, you’ll receive an email to continue"
                actionType="warning"
                v2
                showFooter
            />
        </>
    )
}

export default DecisionIsYes

DecisionIsYes.propTypes = {
    showPaymentPlan: PropTypes.bool,
    setShowPaymentPlan: PropTypes.func,
    showSubscriptionPlan: PropTypes.bool,
    setShowSubscriptionPlan: PropTypes.func,
    updates: PropTypes.array,
    showPaystackModal: PropTypes.bool,
    setShowPaystackModal: PropTypes.func,
    consumerFirstName: PropTypes.string,
    isWithSuggestion: PropTypes.bool,
    estimationId: PropTypes.string,
    refetchUpdates: PropTypes.func,
    consumerEmail: PropTypes.string,
    consumerEstimationData: PropTypes.array,
    accountDetails: PropTypes.object,
    repaymentMethodType: PropTypes.string,
    showSubTenure: PropTypes.bool,
    setShowSubTenure: PropTypes.func,
    isPendingAgreementSigning: PropTypes.bool,
    showAgreementSigningConfirmationModal: PropTypes.bool,
    setShowAgreementSigningConfirmationModal: PropTypes.func,
}
