import styles from 'src/components/AuthenticatedNavBar/navbar.module.scss'
import { Dropdown, Menu } from 'antd'
import { ReactComponent as DropdownIcon } from 'src/assets/images/dropdown.svg'
import { ReactComponent as ShutDownIcon } from 'src/assets/images/shut-down.svg'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { decodeUserInfo } from 'src/utils/auth'

const ProfileDropDown = () => {
    const userInfo = decodeUserInfo()
    const initials =
        userInfo?.firstName?.charAt(0) + userInfo?.lastName?.charAt(0)
    const { InitialsAvatar, options, DropDownLI } = styles

    const { logOut } = useLogOut('/')

    const dropdownMenu = (
        <Menu className="NavBar_Menu">
            <Menu.Item onClick={logOut}>
                <span className={DropDownLI}>
                    <ShutDownIcon />
                    <span>Log Out</span>
                </span>
            </Menu.Item>
        </Menu>
    )
    return (
        <div className={options}>
            <div className={InitialsAvatar}>
                <span>{initials?.toUpperCase()}</span>
            </div>
            <Dropdown
                trigger={['click']}
                overlay={dropdownMenu}
                placement="bottomRight"
            >
                <DropdownIcon style={{ cursor: 'pointer' }} />
            </Dropdown>
        </div>
    )
}

export default ProfileDropDown
