import { ReactComponent as DeleteIcon } from 'src/assets/images/deletemodal-icon.svg'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import { ReactComponent as ConfirmActionIcon } from 'src/assets/images/confirm-action.svg'
import { ReactComponent as MessageIcon } from 'src/assets/images/messagemodal-icon.svg'
import { ReactComponent as StoreIcon } from 'src/assets/images/store.svg'
import { ReactComponent as MailSentIcon } from 'src/assets/images/mail-sent-icon.svg'
import { ReactComponent as SignAgreement } from 'src/assets/images/agreement-icon.svg'
import CountdownTimer from '../CountdownTimer'

export const getIconByActionType = (
    actionType,
    countdownTimerSeconds,
    onTimeEnd,
    actionModalOpen,
) => {
    switch (actionType) {
        case 'delete':
            return <DeleteIcon data-testid="delete-icon" />
        case 'success':
            return <SuccessIcon data-testid="success-icon" />
        case 'warning':
            return <WarningIcon data-testid="warning-icon" />
        case 'confirm-action':
            return <ConfirmActionIcon data-testid="confirm-action-icon" />
        case 'message':
            return <MessageIcon data-testid="message-icon" />
        case 'mail-sent':
            return <MailSentIcon data-testid="mail-sent-icon" />
        case 'store':
            return <StoreIcon data-testid="store-icon" />
        case 'sign-agreement':
            return <SignAgreement data-testid="agreement-icon" />
        case 'countdown-timer':
            if (actionModalOpen) {
                return (
                    <CountdownTimer
                        seconds={countdownTimerSeconds}
                        onTimeEnd={onTimeEnd}
                        margin={'0 0 20px 0'}
                        isActive={actionModalOpen}
                    />
                )
            }
            break
        default:
            return null
    }
}
