import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { format } from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'
import {
    useSSERetrieveBasicInfo,
    useSSESubmitEmploymentInfo,
    useSSESubmitPersonalInfo,
} from 'src/api/shoppingExperience/kyc/queries'
import { detectDescriptionLabel } from '../utils'
import { errorHandler, formatYupError } from 'src/utils/errorHandler'
import {
    removeEmptyValues,
    removeEmptyObjectsV2,
} from 'src/utils/removeEmptyValues'
import { includeHttps } from 'src/utils/formatting'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import {
    validateEmploymentInfoKyc,
    validatePersonalInfoKyc,
} from 'src/utils/validationSchema'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { isMobile } from 'src/utils/mediaQueries'
import { useFormik } from 'formik'
import { useParams, useHistory } from 'react-router-dom'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { handleAppTrackingOnKYC } from '../utils/utils'
import { decodeUserInfo } from 'src/utils/auth'
import {
    submitResidenceKycEmploymentInfoByAdmin,
    submitResidenceKycPersonalInfoByAdmin,
} from 'src/api/admin/assisted-kyc'

export const usePersonalInformation = ({ userType }) => {
    const history = useHistory()
    const { id } = useParams()

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const isAssistedKyc =
        process.env.REACT_APP_FLAG_ADMIN_ASSISTED_KYC === 'true'

    const description = detectDescriptionLabel(userType, isAdminUser)
    const { errorAlert, successAlert } = useCustomToast()
    let buttonState
    const [persistButtonAction, setPersistButtonAction] = useState('')
    const [fieldErrors, setFieldErrors] = useState({})
    const [modalType, setModalType] = useState('warning')
    const [currentTab, setCurrentTab] = useState('1')
    const [activeTab, setActiveTab] = useState('1')
    const [residenceUpdateJustCompleted, setResidenceUpdateJustCompleted] =
        useState(false)
    const [residenceConsumerName, setResidenceConsumerName] = useState('')

    const pageTitle =
        'KYC -  Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const [residentialInfoInputs, setResidentialInfoInputs] = useState({
        firstName: '',
        lastName: '',
        email: '',
        emailVerified: false,
        phone: '',
        phoneVerified: false,
        contactAddress: '',
        contactState: '',
        contactCity: '',
        contactCountry: '',
        confirmAddress: '',
        installationAddress: '',
        installationState: '',
        installationCity: '',
        installationCountry: '',
        // contactInfo
        employmentType: '',
        // employed inputs
        companyName: '',
        jobTitle: '',
        jobStartDate: '',
        employmentContractUrl: '',
        // employed & retired inputs
        linkedinPage: '',
        // retired inputs
        pensionManager: '',
        retirementDate: '',
        // self-employed inputs
        businessName: '',
        businessNameVerified: false,
        businessType: '',
        businessIndustry: '',
        isBusinessRegistered: '',
        cacNumber: '',
        cacNumberVerified: false,
        numberOfYearsInOperation: '',
        website: '',
        businessDescription: '',
        successUpload: { name: '', originFileObj: '', type: '' },
    })
    const employmentInfoFormik = useFormik({
        initialValues: {
            employmentType: residentialInfoInputs.employmentType,
            companyName: residentialInfoInputs?.companyName,
            jobTitle: residentialInfoInputs?.jobTitle,
            jobStartDate: residentialInfoInputs?.jobStartDate,
            linkedinPage: residentialInfoInputs?.linkedinPage,
            pensionManager: residentialInfoInputs?.pensionManager,
            retirementDate: residentialInfoInputs?.retirementDate,
            businessName: residentialInfoInputs?.businessName,
            businessType: residentialInfoInputs?.businessType,
            businessIndustry: residentialInfoInputs?.businessIndustry,
            businessNameVerified:
                residentialInfoInputs?.businessNameVerified || false,
            isBusinessRegistered:
                residentialInfoInputs?.isBusinessRegistered || false,
            cacNumber: residentialInfoInputs?.cacNumber,
            cacNumberVerified:
                residentialInfoInputs?.cacNumberVerified || false,
            numberOfYearsInOperation:
                residentialInfoInputs?.numberOfYearsInOperation || '',
            website: residentialInfoInputs?.website,
            businessDescription: residentialInfoInputs?.businessDescription,
            employmentContractUrl: residentialInfoInputs?.employmentContractUrl,
            employmentSuccessUpload: { name: '', originFileObj: '', type: '' },
        },
        validationSchema: validateEmploymentInfoKyc,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
    })
    const employmentInfoValues = employmentInfoFormik.values

    const personalInfoFormik = useFormik({
        initialValues: {
            firstName: residentialInfoInputs.firstName,
            lastName: residentialInfoInputs.lastName,
            email: residentialInfoInputs.email,
            emailVerified: residentialInfoInputs.emailVerified,
            phone: residentialInfoInputs.phone,
            phoneVerified: residentialInfoInputs.phoneVerified,
            contactAddress: residentialInfoInputs.contactAddress,
            contactState: residentialInfoInputs.contactState,
            contactCity: residentialInfoInputs.contactCity,
            contactCountry: residentialInfoInputs.contactCountry,
            confirmAddress: residentialInfoInputs.confirmAddress,
            installationAddress: residentialInfoInputs.installationAddress,
            installationState: residentialInfoInputs.installationState,
            installationCity: residentialInfoInputs.installationCity,
            installationCountry: residentialInfoInputs.installationCountry,
        },
        validationSchema: validatePersonalInfoKyc,
        validateOnBlur: true,
        enableReinitialize: true,
    })
    const personalInfoValues = personalInfoFormik.values

    const [toastMessage, setToastMessage] = useState({ type: '', message: '' })

    const { sseBasicInfoRefetch, sseBasicInfoFetching } =
        useSSERetrieveBasicInfo({
            enabled: false,
            onSuccess: data => {
                setResidentialInfoInputs(prev => ({
                    ...prev,
                    firstName:
                        data?.consumer?.user?.first_name ??
                        residentialInfoInputs?.firstName,
                    lastName:
                        data?.consumer?.user?.last_name ??
                        residentialInfoInputs?.lastName,
                    email: data?.consumer?.user?.email ?? '',
                    emailVerified:
                        data?.consumer?.user?.email_verified ?? false,
                    phone:
                        parsePhoneNumber(
                            data?.consumer?.user?.phone_number || '',
                            'NG',
                        )?.nationalNumber ?? '',
                    phoneVerified:
                        data?.consumer?.user?.phone_verified ?? false,
                    contactAddress: data?.consumer?.user?.street_address ?? '',
                    contactState:
                        data?.consumer?.user?.state_of_residence ?? '',
                    contactCity:
                        data?.consumer?.user?.city ??
                        residentialInfoInputs.contactCity,
                    contactCountry:
                        data?.consumer?.user?.country ??
                        residentialInfoInputs.contactCountry,
                    confirmAddress:
                        data?.consumer?.user?.street_address ===
                            data?.consumer?.installation_details
                                ?.street_address &&
                        data?.consumer?.user?.state_of_residence ===
                            data?.consumer?.installation_details?.state &&
                        data?.consumer?.user?.city ===
                            data?.consumer?.installation_details?.city
                            ? 'yes'
                            : data?.consumer?.user?.street_address !==
                                  data?.consumer?.installation_details
                                      ?.street_address &&
                              data?.consumer?.installation_details
                                  ?.street_address?.length > 0 &&
                              data?.consumer?.installation_details?.state
                                  ?.length > 0 &&
                              data?.consumer?.installation_details?.city
                                  ?.length > 0
                            ? 'no'
                            : '',
                    installationAddress:
                        data?.consumer?.installation_details?.street_address ??
                        residentialInfoInputs?.installationAddress,
                    installationState:
                        data?.consumer?.installation_details?.state ??
                        residentialInfoInputs?.installationState,
                    installationCity:
                        data?.consumer?.installation_details?.city ??
                        residentialInfoInputs?.installationCity,
                    installationCountry:
                        data?.consumer?.installation_details?.country ??
                        residentialInfoInputs.installationCountry,
                    employmentType: data?.consumer?.employment_status ?? '',
                    companyName:
                        data?.consumer?.employment_details?.company_name ?? '',
                    employmentContractUrl:
                        data?.consumer?.employment_details
                            ?.employment_contract_url ?? '',
                    linkedinPage:
                        data?.consumer?.employment_details?.linkedin_url ?? '',
                    jobTitle:
                        data?.consumer?.employment_details?.job_title ?? '',
                    jobStartDate:
                        data?.consumer?.employment_details
                            ?.employment_start_date ?? '',
                    pensionManager:
                        data?.consumer?.pension_details?.pension_manager ?? '',
                    retirementDate:
                        data?.consumer?.pension_details?.retirement_date ?? '',
                    businessName: data?.consumer?.business?.business_name ?? '',
                    businessNameVerified:
                        data?.consumer?.business?.business_name_verified ??
                        false,
                    businessType: data?.consumer?.business?.business_type ?? '',
                    businessIndustry:
                        data?.consumer?.business?.business_industry ?? '',
                    cacNumber:
                        data?.consumer?.business
                            ?.business_registration_number ?? '',
                    cacNumberVerified:
                        data?.consumer?.business
                            ?.business_registration_number_verified ?? false,
                    isBusinessRegistered:
                        data?.consumer?.business?.is_business_registered ?? '',
                    numberOfYearsInOperation:
                        data?.consumer?.business?.years_in_operation ?? '',
                    website: data?.consumer?.business?.business_website ?? '',
                    businessDescription:
                        data?.consumer?.business?.business_description ?? '',
                }))
                setModalType('warning')
                setFieldErrors({})
            },
            onError: err => {
                setToastMessage({
                    type: 'error-secondary',
                    message: errorHandler(err),
                })
                setModalType('warning')
                setFieldErrors({})
            },
        })

    const refetchInfo = () => {
        sseBasicInfoRefetch()
    }

    useEffect(() => {
        refetchInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // save employment info
    const { submitEmploymentInfo, employmentInfoError, employmentInfoLoading } =
        useSSESubmitEmploymentInfo()

    let contactAddress = {
        street_address: personalInfoValues.contactAddress,
        city: personalInfoValues.contactCity,
        state_of_residence: personalInfoValues?.contactState,
        country: personalInfoValues.contactCountry,
    }
    let installationAddress = {
        street_address: personalInfoValues.installationAddress,
        city: personalInfoValues.installationCity,
        state: personalInfoValues.installationState,
        country: personalInfoValues?.installationCountry,
    }

    const businessDetails = {
        business_name: employmentInfoValues?.businessName ?? '',
        business_type: employmentInfoValues?.businessType,
        business_industry: employmentInfoValues?.businessIndustry,
        years_in_operation: employmentInfoValues?.numberOfYearsInOperation,
        business_website: includeHttps(employmentInfoValues?.website),
        business_description: employmentInfoValues?.businessDescription,
    }

    // save personal info
    const { submitPersonalInfo, personalInfoError, personalInfoLoading } =
        useSSESubmitPersonalInfo()

    const adminSubmitPersonalInfo = useMutation({
        mutationFn: ({ estimationId, payload }) =>
            submitResidenceKycPersonalInfoByAdmin(estimationId, payload),
        onSuccess: (response, variables) => {
            if (response.data) {
                variables.handleSuccess?.()
            }
        },
    })

    const adminSubmitEmploymentInfo = useMutation({
        mutationFn: ({ estimationId, payload }) =>
            submitResidenceKycEmploymentInfoByAdmin(estimationId, payload),
        onSuccess: (response, variables) => {
            if (response.data) {
                variables.handleSuccess?.()
            }
        },
    })

    const { uploadToS3Mutate, uploadToS3Error } = useUploadToS3({
        onSuccess: data => {
            const personalInfoPayload = removeEmptyValues({
                button: buttonState || persistButtonAction,
                first_name: personalInfoValues.firstName,
                last_name: personalInfoValues.lastName,
                contact_address: removeEmptyValues(contactAddress),
                installation_address: removeEmptyValues(installationAddress),
            })
            const payload = removeEmptyValues({
                button: buttonState || persistButtonAction,
                employment_type: employmentInfoValues?.employmentType,
                company_name: employmentInfoValues?.companyName,
                job_title: employmentInfoValues.jobTitle,
                employment_start_date:
                    employmentInfoValues?.jobStartDate?.length > 0 &&
                    ['Full Time', 'Part Time', 'Contract'].includes(
                        employmentInfoValues?.employmentType,
                    )
                        ? format(
                              new Date(employmentInfoValues?.jobStartDate),
                              'yyyy-MM-dd',
                          )
                        : '',
                employment_contract_url:
                    employmentInfoValues?.employmentSuccessUpload?.name
                        ?.length > 0 &&
                    ['Full Time', 'Part Time', 'Contract'].includes(
                        employmentInfoValues?.employmentType,
                    )
                        ? data
                        : '',
                linkedin_url: [
                    'Full Time',
                    'Part Time',
                    'Contract',
                    'Retired',
                ].includes(employmentInfoValues?.employmentType)
                    ? includeHttps(employmentInfoValues?.linkedinPage)
                    : '',
                pension_manager: employmentInfoValues.pensionManager || '',
                retirement_date:
                    employmentInfoValues?.retirementDate?.length > 0 &&
                    employmentInfoValues?.employmentType === 'Retired'
                        ? format(
                              new Date(employmentInfoValues?.retirementDate),
                              'yyyy-MM-dd',
                          )
                        : '',
                is_business_registered:
                    employmentInfoValues?.employmentType === 'Self Employed'
                        ? employmentInfoValues?.isBusinessRegistered
                        : '',
                business_details:
                    employmentInfoValues?.employmentType === 'Self Employed'
                        ? removeEmptyValues(businessDetails)
                        : '',
            })

            if (persistButtonAction === 'Submit' || buttonState === 'Submit') {
                validateEmploymentInfoKyc
                    .validate(employmentInfoValues, {
                        abortEarly: false,
                    })
                    .then(() => {
                        handleSubmitEmploymentInfo(personalInfoPayload, payload)
                    })
                    .catch(err => {
                        let errList = formatYupError(err)
                        employmentInfoFormik.setTouched(errList)
                        employmentInfoFormik.setErrors(errList)
                        errorAlert(Object.values(errList)[0])
                    })
            } else {
                handleSubmitEmploymentInfo(personalInfoPayload, payload)
            }
        },
        onError: err => {
            if (persistButtonAction === 'Submit' || buttonState === 'Submit') {
                setToastMessage({
                    type: 'error-secondary',
                    message: errorHandler(err),
                })
            }
        },
    })

    const handleSubmitEmploymentInfo = (personalInfoPayload, payload) => {
        submitEmploymentInfo(payload, {
            onSuccess: () => {
                if (buttonState === 'Save') {
                    setModalType('countdown-timer')
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        eventTrackers['sseSaveBasicInfoSuccessfully'].action,
                        eventTrackers['sseSaveBasicInfoSuccessfully'].label,
                        eventTrackers['sseSaveBasicInfoSuccessfully'].category,
                        ['MP', 'GA'],
                        'event',
                        {
                            personal_info: personalInfoPayload,
                            employment_info: payload,
                        },
                    )
                }
                if (buttonState !== 'Save') {
                    successAlert(
                        'Employment information submitted successfully',
                        isMobile ? 320 : 500,
                    )
                    setTimeout(() => {
                        history.push(
                            '/consumer/account-setup/identity-information',
                        )
                    }, 2000)
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        eventTrackers['sseSubmitBasicInfoSuccessfully'].action,
                        eventTrackers['sseSubmitBasicInfoSuccessfully'].label,
                        eventTrackers['sseSubmitBasicInfoSuccessfully']
                            .category,
                        ['MP', 'GA'],
                        'event',
                        {
                            employment_info: payload,
                            personal_info: personalInfoPayload,
                        },
                    )
                    handleAppTrackingOnKYC('completedKYCCategory', pageTitle)
                }
            },
            onError: err => {
                if (buttonState !== 'Save') {
                    errorAlert(
                        errorHandler(err) ||
                            'Employment information upload failed',
                    )
                } else {
                    errorAlert('Failed to save, please try again.')
                }
            },
        })
    }

    const handleSubmitBasicInfo = personalInfoPayload => {
        submitPersonalInfo(personalInfoPayload, {
            onSuccess: () => {
                if (buttonState === 'Submit') {
                    successAlert(
                        'Personal information submitted successfully',
                        isMobile ? 320 : 500,
                    )
                    setActiveTab('2')
                    setCurrentTab('2')
                } else {
                    setModalType('countdown-timer')
                }
            },
            onError: err => {
                if (buttonState !== 'Save') {
                    errorAlert(
                        errorHandler(err) ||
                            'Personal information upload failed',
                    )
                } else {
                    errorAlert('Failed to save, please try again.')
                }
            },
        })
    }

    const handleAssistedKycSave = (personalInfoPayload, payload) => {
        delete personalInfoPayload.button
        delete payload.button

        const updatedPayload = removeEmptyObjectsV2(payload)

        const handleSubmitPersonalInfo = () => {
            adminSubmitPersonalInfo.mutate({
                estimationId: id,
                payload: removeEmptyObjectsV2(personalInfoPayload),
                handleSuccess: () => {
                    setResidenceConsumerName(
                        `${personalInfoPayload.first_name || ''} ${
                            personalInfoPayload.last_name || ''
                        }`,
                    )
                    setResidenceUpdateJustCompleted(true)
                    personalInfoFormik.setTouched({})
                },
            })
        }

        if (Object.keys(updatedPayload).length) {
            adminSubmitEmploymentInfo.mutate({
                estimationId: id,
                payload: updatedPayload,
                handleSuccess: () => {
                    setResidenceConsumerName(
                        `${personalInfoPayload.first_name || ''} ${
                            personalInfoPayload.last_name || ''
                        }`,
                    )

                    employmentInfoFormik.setTouched({})

                    if (Object.keys(personalInfoPayload).length) {
                        handleSubmitPersonalInfo()
                    } else {
                        personalInfoFormik.setTouched({})
                        setResidenceUpdateJustCompleted(true)
                    }
                },
            })
        } else if (Object.keys(personalInfoPayload).length) {
            handleSubmitPersonalInfo()
        }
    }

    const persistInfo = button => {
        setPersistButtonAction(button)
        buttonState = button
        let personalInfoValues = personalInfoFormik.values
        let employmentInfoValues = employmentInfoFormik.values

        if (isAssistedKyc && isAdminUser) {
            const personalInfoUpdateData = {}
            const employmentInfoUpdateData = {}
            let changedAddress = false

            const addressKeys = [
                'contactAddress',
                'contactCity',
                'contactState',
                'contactCountry',
                'installationAddress',
                'installationCity',
                'installationState',
                'installationCountry',
            ]

            for (const key of Object.keys(personalInfoFormik.touched)) {
                personalInfoUpdateData[key] = personalInfoFormik.values[key]

                if (addressKeys.includes(key)) {
                    changedAddress = true
                }
            }

            for (const key of Object.keys(employmentInfoFormik.touched)) {
                employmentInfoUpdateData[key] = employmentInfoFormik.values[key]
            }

            if (!changedAddress) {
                contactAddress = {}
                installationAddress = {}
            }

            personalInfoValues = personalInfoUpdateData
            employmentInfoValues = employmentInfoUpdateData
        }

        const personalInfoPayload = removeEmptyValues({
            button: button,
            first_name: personalInfoValues.firstName,
            last_name: personalInfoValues.lastName,
            contact_address: removeEmptyValues(contactAddress),
            installation_address: removeEmptyValues(installationAddress),
        })
        const payload = removeEmptyValues({
            button: button,
            employment_type: employmentInfoValues?.employmentType,
            company_name: employmentInfoValues?.companyName,
            job_title: employmentInfoValues.jobTitle,
            employment_start_date:
                employmentInfoValues?.jobStartDate?.length > 0 &&
                ['Full Time', 'Part Time', 'Contract'].includes(
                    employmentInfoValues?.employmentType,
                )
                    ? format(
                          new Date(employmentInfoValues?.jobStartDate),
                          'yyyy-MM-dd',
                      )
                    : '',
            employment_contract_url:
                employmentInfoValues?.employmentSuccessUpload?.name?.length >
                    0 &&
                ['Full Time', 'Part Time', 'Contract'].includes(
                    employmentInfoValues?.employmentType,
                )
                    ? employmentInfoValues.employmentContractUrl
                    : '',
            linkedin_url: [
                'Full Time',
                'Part Time',
                'Contract',
                'Retired',
            ].includes(employmentInfoValues?.employmentType)
                ? includeHttps(employmentInfoValues?.linkedinPage)
                : '',
            pension_manager: employmentInfoValues.pensionManager || '',
            retirement_date:
                employmentInfoValues?.retirementDate?.length > 0 &&
                employmentInfoValues?.employmentType === 'Retired'
                    ? format(
                          new Date(employmentInfoValues?.retirementDate),
                          'yyyy-MM-dd',
                      )
                    : '',
            is_business_registered:
                employmentInfoValues?.employmentType === 'Self Employed'
                    ? employmentInfoValues?.isBusinessRegistered
                    : '',
            business_details:
                employmentInfoValues?.employmentType === 'Self Employed'
                    ? removeEmptyValues(businessDetails)
                    : '',
        })

        if (
            employmentInfoValues.employmentSuccessUpload?.name?.length > 0 &&
            currentTab === '2'
        ) {
            uploadToS3Mutate({
                category: 'EMPLOYMENT_CONTRACT',
                file: employmentInfoValues?.employmentSuccessUpload,
            })
        } else if (button === 'Submit' && currentTab === '2') {
            if (isAssistedKyc && isAdminUser) {
                handleAssistedKycSave(personalInfoPayload, payload)
            } else {
                validateEmploymentInfoKyc
                    .validate(employmentInfoValues, { abortEarly: false })
                    .then(() => {
                        handleSubmitEmploymentInfo(personalInfoPayload, payload)
                    })
                    .catch(err => {
                        const errList = formatYupError(err)
                        employmentInfoFormik.setTouched(errList)
                        employmentInfoFormik.setErrors(errList)
                        errorAlert(Object.values(errList)[0])
                    })
            }
        } else if (button === 'Save' && currentTab === '2') {
            handleSubmitEmploymentInfo(personalInfoPayload, payload)
        } else {
            if (button === 'Submit' && currentTab === '1') {
                if (isAssistedKyc && isAdminUser) {
                    handleAssistedKycSave(personalInfoPayload, payload)
                } else {
                    validatePersonalInfoKyc
                        .validate(personalInfoValues, { abortEarly: false })
                        .then(() => {
                            handleSubmitBasicInfo(personalInfoPayload)
                        })
                        .catch(err => {
                            let errList = formatYupError(err)
                            personalInfoFormik.setTouched(errList)
                            personalInfoFormik.setErrors(errList)
                            errorAlert(Object.values(errList)[0])
                        })
                }
            } else if (button === 'Save' && currentTab === '1') {
                handleSubmitBasicInfo(personalInfoPayload)
            }
        }
    }

    const persistResidentialInfoLoading =
        employmentInfoLoading || personalInfoLoading

    const errorPersonalInfo =
        errorHandler(personalInfoError?.response?.data?.message) ||
        errorHandler(personalInfoError?.response?.data?.errors) ||
        errorHandler(employmentInfoError?.response?.data?.message) ||
        errorHandler(employmentInfoError?.response?.data?.errors) ||
        errorHandler(uploadToS3Error) ||
        fieldErrors

    return {
        description,
        sseBasicInfoFetching,
        residentialInfoInputs,
        setResidentialInfoInputs,
        persistInfo,
        persistResidentialInfoLoading,
        errorPersonalInfo,
        refetchInfo,
        toastMessage,
        setToastMessage,
        setFieldErrors,
        modalType,
        setModalType,
        currentTab,
        setCurrentTab,
        activeTab,
        setActiveTab,
        personalInfoFormik,
        employmentInfoFormik,
        assistedKycResidenceUpdateLoading:
            adminSubmitEmploymentInfo.isLoading ||
            adminSubmitPersonalInfo.isLoading,
        residenceUpdateJustCompleted,
        residenceConsumerName,
        setResidenceUpdateJustCompleted,
        setResidenceConsumerName,
    }
}
