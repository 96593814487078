import { differenceInYears } from 'date-fns'
import queryString from 'query-string'

export const formatInteger = int => {
    return int.toLocaleString(undefined, { minimumFractionDigits: 2 })
}

export const toTitleCase = str => {
    if (!str || str === 'N/A') {
        return str
    }
    return str
        .trim()
        .toLowerCase()
        .split(' ')
        .map(word => {
            return word.replace(word[0], word[0].toUpperCase())
        })
        .join(' ')
}

export const formatDOB = value => {
    /**
     * This utility is to format a datestring from dd-mm-yyyy to mm-dd-yyyy
     */
    let dobValue = ''
    if (value) {
        try {
            const dateOfBirthSplit = value.split('-')
            const month = dateOfBirthSplit[1]
            const day = dateOfBirthSplit[0]
            const year = dateOfBirthSplit[2]
            dobValue = `${month}-${day}-${year}`
        } catch (error) {
            dobValue = ''
        }
    }
    return dobValue
}
export const subStringText = (text, textLength = 41) => {
    if (text) {
        if (text?.length < textLength) {
            return text
        } else if (textLength === 1) {
            return text.substring(0, textLength) + '.'
        } else {
            return text.substring(0, textLength - 1) + '...'
        }
    } else {
        return ''
    }
}

export const splitByWords = (text, length) => {
    if (text?.split(' ').length < length) {
        return text
    } else {
        let formattedText =
            text?.split(' ').splice(0, length).join(' ') + '.....'
        return formattedText
    }
}

export const replaceSpacewithUnderscore = text => {
    return text.split(' ').join('_')
}

export const convertToSentenceCase = string => {
    if (string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1)
    } else {
        return ''
    }
}

export const roundToTwo = num => {
    return +(Math.round(num + 'e+2') + 'e-2')
}

export const numberWithCommas = num => {
    let convertedNum = num?.toString()
    let roundedNum = roundToTwo(
        Number(
            convertedNum?.includes(',')
                ? convertedNum?.replaceAll(',', '')
                : convertedNum,
        ),
    )
    return roundedNum?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const floatWithCommas = num => {
    if (num === '') return ''
    let int = num.includes('.') ? num.split('.')[0] : num
    let intWithoutComma = num.includes(',') ? int.replace(/,/g, '') : int
    let decimal = num.includes('.') && num.split('.')[1]
    let intWithCommas = Number(intWithoutComma).toLocaleString()
    let result = num.includes('.')
        ? intWithCommas + '.' + decimal
        : intWithCommas
    return result
}

export const convertToFloatWithoutCommas = num => {
    if (num === '') return ''
    let int = num.includes('.') ? num.split('.')[0] : num
    let intWithoutComma = num.includes(',') ? int.replaceAll(',', '') : int
    let decimal = num.includes('.') && num.split('.')[1]
    let result = num.includes('.')
        ? intWithoutComma + '.' + decimal
        : intWithoutComma
    return roundToTwo(parseFloat(result))
}

export const truncateFileName = name => {
    if (name?.length > 33) {
        const first70Percent = Math.floor(0.75 * name?.length)
        return '...' + name?.substring(first70Percent, name?.length)
    } else {
        return name
    }
}

export const truncateText = (text, wordLimit) => {
    if (typeof text !== 'string') {
        throw new Error('Text must be a string')
    }
    if (typeof wordLimit !== 'number' || wordLimit < 0) {
        throw new Error('Word limit must be a non-negative number')
    }

    const words = text.split(/\s+/)

    if (words.length <= wordLimit) {
        return text
    }

    return words.slice(0, wordLimit).join(' ') + '...'
}

export const truncateSentence = (sentence, characterLimit) => {
    if (typeof sentence !== 'string') {
        throw new Error('Text must be a string')
    }
    if (typeof characterLimit !== 'number' || characterLimit < 0) {
        throw new Error('Word limit must be a non-negative number')
    }
    if (sentence.length <= characterLimit) {
        return sentence
    }
    return `${sentence.slice(0, characterLimit)}...`
}

export const formatPhoneNumber = (phone_number, country_code = '+234') => {
    if (phone_number?.startsWith(country_code)) {
        return phone_number
    }

    let number
    if (country_code === '+234' && phone_number?.length === 10) {
        number = phone_number
    } else if (country_code === '+234' && phone_number?.length === 11) {
        number = phone_number?.substring(1)
    } else number = phone_number
    return country_code + number
}

//remove country code from a nigerian number
export const removeCountryCodefromNGNNum = phone_number => {
    return phone_number?.substring(4)
}

export const convertArrayDataToWord = (data = [], element = '_') => {
    let newData = []
    if (data?.length > 0) {
        data?.forEach(item => {
            const splittedElements = item?.split(element)?.join(' ')
            let spacedSentenceCaseElement = splittedElements
                ?.toLowerCase()
                ?.trim()
                ?.split(/[' ']/g)
                ?.map(word =>
                    word?.replace(word[0], word[0]?.toString()?.toUpperCase()),
                )
                ?.join(' ')

            newData?.push(spacedSentenceCaseElement)
        })
    }

    return newData
}

export const convertArrayDataToSnakeCase = (data = [], element = ' ') => {
    let newData = []
    if (data?.length > 0) {
        data?.forEach(item => {
            const splittedElements = item?.split(element)?.join('_')
            let finalElementValue = splittedElements?.toLowerCase()?.trim()

            newData?.push(finalElementValue)
        })
    }

    return newData
}

export const convertToInitials = (first_name, last_name) => {
    let firstName = first_name?.substring(0, 1).toUpperCase()
    let lastName = last_name?.substring(0, 1).toUpperCase()
    let initials = `${firstName}${lastName}`

    return initials
}

export const replaceHtmlTags = str => {
    return str?.replace(/(<([^>]+)>)/gi, '')
}

export const titleCase = str => {
    return str
        ?.toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
}

export const removeEmptyFieldFromObject = obj => {
    Object.keys(obj).forEach(v => {
        if (obj[v] === '') {
            delete obj[v]
        }
    })
    return obj
}

export const removeUnderScore = text => {
    return text.split('_').join(' ')
}

export const formatDate = date => {
    if (!(date instanceof Date) || isNaN(date)) {
        return
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day} ${month} ${year} at ${hours}:${minutes}`
}

export const extractFileNameFromUrl = url => {
    if (!url) {
        return ''
    }
    const urlParts = url.split('/')
    const fileNameWithExtension = urlParts[urlParts.length - 1]
    const fileName = fileNameWithExtension.split(/[?#]/)[0]
    return fileName
}

export const includeHttps = url => {
    const includesProtocol = url?.includes(['http'])
    if (includesProtocol) {
        return url
    } else if (url?.length > 0) {
        return 'https://' + url
    }
}

// replace single underscore with space, double underscore with dash and set to title case
export const removeUnderscoresAndToTitleCase = str => {
    return str
        .replace(/__+/g, ' - ')
        .replace(/_/g, ' ')
        .replace(/\w\S*/g, txt => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
}

// revert data formatted using removeUnderscoresAndToTitleCase to original format
export const revertToOriginalFormat = str => {
    return str.replace(/ - /g, '__').replace(/ /g, '_').toLowerCase()
}

export const formatGender = str => {
    if (!str) return 'N/A'
    if (str.toLowerCase() === 'm' || str.toLowerCase().startsWith('m')) {
        return 'Male'
    } else {
        return 'Female'
    }
}

export const getAgeInYearsFromDob = (dateOfBirth = '') => {
    const currentDate = new Date()
    const correctDateFormat = dateOfBirth?.split('-')?.reverse()?.join('-')
    const parsedDOB = new Date(correctDateFormat)
    // Calculate the difference in years
    const age = differenceInYears(currentDate, parsedDOB)
    return age
}

export const formatBase64Image = base64ImageString => {
    // Your base64 image string
    // const base64ImageString = 'data:image/png;base64,iVBORw0KGgo...'; // Replace with your actual base64 string

    // Step 1: Decode the Base64 string to binary data
    const binaryData = new TextDecoder(base64ImageString)

    // Step 2: Create a Blob object from the binary data
    const mimeString = 'image/png'
    const arrayBuffer = new ArrayBuffer(binaryData.length)
    const uintArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < binaryData.length; i++) {
        uintArray[i] = binaryData.charCodeAt(i)
    }

    const blob = new Blob([arrayBuffer], { type: mimeString })

    // Step 3: Create a URL for the Blob object
    const imageUrl = URL.createObjectURL(blob)
    // Step 4: Use the URL to display the image in an HTML <img> element
    const imgElement = document.createElement('img')
    imgElement.src = imageUrl

    // Append the image element to the document or do further processing
    document.body.appendChild(imgElement)
    return imageUrl
}

export const getLengthofEnergyInputArray = (arr = []) => {
    const validEnergyList = arr.filter(el => {
        if (
            el.quantity &&
            (el.backup_hours || el.backup_hours_night) &&
            (el.powerRating || el.power_rating) &&
            (el.unitOfMeasurement || el.unit_of_measurement)
        ) {
            return el
        }
    })

    return validEnergyList.length
}

export const getSearchParamsFromUrl = () => {
    return queryString.parse(window.location.search)
}

export const addQueryParamsToUrl = (currentUrl, queryParamsObject) => {
    const url = new URL(currentUrl)
    for (const key in queryParamsObject) {
        url.searchParams.set(key, queryParamsObject[key])
    }

    return url.search
}
export const removeQueryParamFromUrl = (url, paramToRemove) => {
    const urlObject = new URL(url)

    // Get the search parameters from the URL
    const searchParams = urlObject.searchParams

    // Remove the specified parameter
    searchParams.delete(paramToRemove)

    // Create a new URL with the updated search parameters
    const newUrl = urlObject.search

    return newUrl
}

export const removeAllQueryParamsFromUrl = url => {
    const urlObject = new URL(url)

    urlObject.search = ''

    const newUrl = urlObject.toString()

    return newUrl
}

export const isNumeric = value => {
    return !isNaN(parseFloat(value)) && isFinite(value)
}

// format input values
const formatPersonNameInput = value => {
    return value.replace(/[^a-zA-Z-]/g, '')
}

const formatPhoneNumberInput = value => {
    return value.replace(/[^\d]/g, '')
}

export const formatPlainNumberInput = value => {
    return value.replace(/[^\d]/g, '')
}

export const formatCurrencyNumberInput = value => {
    const numericValue = value.replace(/[^\d]/g, '')
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatInputValue = (name, value) => {
    const formatters = {
        firstname: formatPersonNameInput,
        firstName: formatPersonNameInput,
        lastname: formatPersonNameInput,
        lastName: formatPersonNameInput,
        phonenumber: formatPhoneNumberInput,
        phone: formatPhoneNumberInput,
        minValue: formatPlainNumberInput,
        maxValue: formatPlainNumberInput,
        netMonthlySalary: formatCurrencyNumberInput,
        bvn: formatPlainNumberInput,
    }

    const formatter = formatters[name]
    return formatter ? formatter(value) : value
}

export const convertQueryParamsToObject = queries => {
    const object = {}

    queries.forEach(item => {
        const [key, value] = item.split(':')
        object[key] = value || null
    })

    return object
}

export const getFileNameFromUrl = url => {
    if (!url) {
        return ''
    }
    const urlObj = new URL(url)
    const pathname = urlObj.pathname
    const segments = pathname.split('/')
    return segments.pop()
}

export const getGreetingTime = () => {
    const currentTime = new Date().getHours()
    let greetingMessage

    if (currentTime < 12) {
        greetingMessage = 'Morning'
    } else if (currentTime >= 12 && currentTime < 17) {
        greetingMessage = 'Afternoon'
    } else {
        greetingMessage = 'Evening'
    }

    return greetingMessage
}

export const getRootDomain = url => {
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url
    }

    const hostname = new URL(url).hostname
    const parts = hostname.split('.').reverse()

    if (parts.length >= 2) {
        // Handle domains with SLD and a ccTLD
        if (parts[1].length <= 3 && parts[0].length <= 3) {
            return parts[2] + '.' + parts[1] + '.' + parts[0]
        } else {
            return parts[1] + '.' + parts[0]
        }
    }
    return hostname
}
